import { UPDATE_MODAL, CLOSE_MODAL } from '../actions/types';

const initialState = {
  showModal: ''
};

const updateModal = (state, modalFor) => {
  return {
    ...state,
    showModal: modalFor
  };
};

const closeModal = state => {
  return {
    ...state,
    showModal: ''
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MODAL:
      return updateModal(state, action.payload);
    case CLOSE_MODAL:
      return closeModal(state, action.payload);
    default:
      return state;
  }
}
