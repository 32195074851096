import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import AccountingMenu from './AccountingMenu';
import AccountingHome from './AccountingHome';
import EditItem from './assignments/EditItem';
import AddItem from './assignments/AddItem';
import AccountingIt from './it/Home';
import Export from './export/Export';

import './accounting.css';

class Accounting extends Component {
  render() {
    return (
      <React.Fragment>
        <AccountingMenu />
        <Route exact path="/accounting" component={AccountingHome} />
        <Route exact path="/accounting/it" component={AccountingIt} />
        <Route exact path="/accounting/add" component={AddItem} />
        <Route exact path="/accounting/edit/:id" component={EditItem} />
        <Route exact path="/accounting/export" component={Export} />
      </React.Fragment>
    );
  }
}

export default Accounting;
