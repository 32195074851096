import React from 'react';

import AllItems from './Alltems';
import Totals from './Totals';

const AccountingHome = () => {
  return (
    <div className="grid maxWidth">
      <div className="grid-1-8">
        <AllItems />
      </div>
      <div className="grid-8-10">
        <Totals />
      </div>
    </div>
  );
};

export default AccountingHome;
