import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { deleteLogItem, updateLoggedTimeToday } from '../../actions/log';

interface Props {
  id: number;
  deleteLogItem(num: number): void;
  updateLoggedTimeToday(): void;
}

const ItemActions: React.FunctionComponent<Props> = props => {
  const { id } = props;

  const deleteItemHandler = async (id: number) => {
    await props.deleteLogItem(id);
    props.updateLoggedTimeToday();
  };

  return (
    <div className="log-item-actions dib ml1-5">
      <Link
        to={{
          pathname: `/log/edit/${id}`
        }}
        className="icon-btn line mr1"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <line x1="5" y1="12" x2="19" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
      </Link>

      <button className="icon-btn line" onClick={() => deleteItemHandler(id)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <polyline points="3 6 5 6 21 6" />
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
        </svg>
      </button>
    </div>
  );
};

const mapDispatchToProps = { deleteLogItem, updateLoggedTimeToday };

export default connect(
  null,
  mapDispatchToProps
)(ItemActions);
