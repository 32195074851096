import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ''
    };
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });

    // Pass value to Parent Component
    this.props.passStateToParent(this.props.name, e.currentTarget.value);
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    // Pass value to Parent Component
    this.props.passStateToParent(this.props.name, e.currentTarget.innerText);
  };

  onKeyUp = e => {
    // Unfocus the field
    if (e.key === 'Escape') {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false
      });
      e.target.blur();
    }
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // Prevent default for enter key
    if (e.keyCode === 13) {
      e.preventDefault();
    }

    // User pressed the tab key, update the input and close the suggestions
    if (e.keyCode === 9) {
      // If the suggestion box is open
      if (this.state.filteredSuggestions.length > 0) {
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion]
        });

        // Pass value to Parent Component
        this.props.passStateToParent(this.props.name, filteredSuggestions[activeSuggestion]);

        // If the suggestion box is closed
      } else {
        this.props.passStateToParent(this.props.name, this.state.userInput);
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false
        });
      }
    }

    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onKeyUp,
      state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput }
    } = this;

    const { label, name, type, isRequired, value, customClass, id, placeholder } = this.props;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="autocomplete-suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'autocomplete-suggestion-active';
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="autocomplete-no-suggestions">
            <em>That's new! :-)</em>
          </div>
        );
      }
    }

    return (
      <div className={`minimal-form-item ${name}${customClass ? ' ' + customClass : ''}`}>
        <label htmlFor={id || name} className={`minimal-form ${name}`}>
          {label}
        </label>
        <span className={`dib error minimal-form ml0-5 hidden ${name}`}>
          {isRequired ? 'Required' : ''}
        </span>
        <React.Fragment>
          <input
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            value={value || userInput}
            name={name}
            id={id || name}
            type={type}
            className="minimal-form minimal-form-field"
            required={isRequired}
            placeholder={placeholder || label}
          />
          {suggestionsListComponent}
        </React.Fragment>
      </div>
    );
  }
}

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array)
};

Autocomplete.defaultProps = {
  suggestions: []
};

export default Autocomplete;
