import React, { Component } from 'react';
import { connect } from 'react-redux';
import { currencySymbol } from '../../../utils/currencyHelpers';
import { amountWithCommas } from '../../../utils/currencyHelpers';
import TotalsPeriodSelector from './TotalsPeriodSelector';

class Totals extends Component {
  revenue = period => {
    return this.props.accountingIt.byId
      .map(
        id =>
          this.props.accountingIt.records[id].invoice_date &&
          new Date(this.props.accountingIt.records[id].invoice_date) > period
            ? Number(this.props.accountingIt.records[id].total)
            : null
      )
      .reduce((a, b) => a + b, 0);
  };

  revenueInSEK = period => {
    return this.props.accountingIt.byId
      .map(
        id =>
          this.props.accountingIt.records[id].invoice_date &&
          this.props.accountingIt.records[id].total_sek > 0 &&
          new Date(this.props.accountingIt.records[id].invoice_date) > period
            ? Number(this.props.accountingIt.records[id].total_sek)
            : null
      )
      .reduce((a, b) => a + b, 0);
  };

  render() {
    const period = new Date(this.props.accountingIt.incomePeriod);
    return (
      <div className="totals">
        <h1 className="h1-5">Income</h1>
        <TotalsPeriodSelector />

        {this.revenue(period).length === 0 ? (
          <h4 className="mt2">No income for this period</h4>
        ) : (
          <React.Fragment>
            <h2 className="totals-income-amount mt1-5">
              <span className="totals-currency-symbol">€</span>
              {amountWithCommas(this.revenue(period))}
            </h2>

            <div className="totals-line" />
            <h2 className="totals-income-amount">
              <span className="totals-currency-symbol">{currencySymbol('SEK')}</span>
              {amountWithCommas(this.revenueInSEK(period))}
            </h2>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = accountingIt => accountingIt;

export default connect(mapStateToProps)(Totals);
