import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { deleteAssignmentItem } from '../../../actions/accounting';
import ItemActions from './ItemActions';
import ItemMoreInfo from './ItemMoreInfo';
import { trimString } from '../../../utils/dataHelpers';
import { currencySymbol, amountWithCommas } from '../../../utils/currencyHelpers';
import { toMonthDayCommaYear, daysFromNow } from '../../../utils/timeHelpers';

class Item extends Component {
  state = {
    showMoreInfo: false
  };

  showMoreInfo = e => {
    this.setState({ showMoreInfo: !this.state.showMoreInfo });
  };

  render() {
    const id = this.props.id;
    const { showMoreInfo } = this.state;

    const invoiceStatus = () => {
      if (this.props.accountingAssignments.records[id].status === 'paid') {
        return (
          <React.Fragment>
            <div className="invoice-status dib">
              {`Invoice #${
                this.props.accountingAssignments.records[id].invoice_number_with_prefix
              } paid on ${toMonthDayCommaYear(
                this.props.accountingAssignments.records[id].payment_date
              )}`}
            </div>
            <div className="invoice-status-dot green dib ml1" />
          </React.Fragment>
        );
      } else if (this.props.accountingAssignments.records[id].status === 'sent') {
        return (
          <React.Fragment>
            <div className="invoice-status dib">{`Invoice #${
              this.props.accountingAssignments.records[id].invoice_number_with_prefix
            } sent ${daysFromNow(
              this.props.accountingAssignments.records[id].invoice_date
            )} days ago`}</div>
            <div className="invoice-status-dot yellow dib ml1" />
            {paymentDelayAlert()}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className="invoice-status dib">No invoice sent</div>
            <div className="invoice-status-dot red dib ml1" />
          </React.Fragment>
        );
      }
    };

    const paymentDelayAlert = () => {
      const daysSinceInvoice = daysFromNow(
        this.props.accountingAssignments.records[id].invoice_date
      );
      if (daysSinceInvoice > 30) {
        return (
          <div className="payment-delay icon-btn red line dib ml1 blink">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="12" />
              <line x1="12" y1="16" x2="12" y2="16" />
            </svg>
          </div>
        );
      }
    };

    return (
      <li key={id} className="accounting-item db mb0-75">
        <span
          className="dib pointer"
          onClick={() => this.setState({ showMoreInfo: !this.state.showMoreInfo })}
          title={this.props.accountingAssignments.records[id].client}
        >
          <div className="dib">
            <div className="dib">
              <span className="h3 c-black">
                {this.props.accountingAssignments.records[id].budget ===
                this.props.accountingAssignments.records[id].revenue
                  ? trimString(this.props.accountingAssignments.records[id].client, 30)
                  : trimString(this.props.accountingAssignments.records[id].client, 20)}
              </span>
            </div>

            <span className="h3 c-black"> - </span>

            <div className="dib">
              <span className="h3 c-black">
                {currencySymbol(this.props.accountingAssignments.records[id].currency)}
                {amountWithCommas(this.props.accountingAssignments.records[id].budget)}
              </span>

              {this.props.accountingAssignments.records[id].budget !==
              this.props.accountingAssignments.records[id].revenue ? (
                <span className="h3-5 ml0-5 c-black">
                  ({currencySymbol(this.props.accountingAssignments.records[id].currency)}
                  {amountWithCommas(this.props.accountingAssignments.records[id].revenue)})
                </span>
              ) : null}
            </div>
          </div>
          {invoiceStatus()}
        </span>
        <ItemActions id={id} props={this.props} />
        {showMoreInfo ? <ItemMoreInfo item={this.props.accountingAssignments.records[id]} /> : null}
      </li>
    );
  }
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  accountingAssignments: PropTypes.shape({
    records: PropTypes.object.isRequired,
    byId: PropTypes.array.isRequired
  }),
  deleteAssignmentItem: PropTypes.func.isRequired
};

function mapStateToProps({ accountingAssignments }) {
  return { accountingAssignments };
}

export default connect(
  mapStateToProps,
  { deleteAssignmentItem }
)(Item);
