import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ItemActions = ({ id, props }) => {
  return (
    <div className="diary-item-actions dib ml1-5">
      <Link
        to={{
          pathname: `/diary/edit/${id}`,
          id
        }}
        className="icon-btn line mr1"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <line x1="5" y1="12" x2="19" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
      </Link>

      <button className="icon-btn line" onClick={() => props.deleteDiaryItem(id)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <polyline points="3 6 5 6 21 6" />
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
        </svg>
      </button>
    </div>
  );
};

ItemActions.propTypes = {
  id: PropTypes.number.isRequired,
  props: PropTypes.shape({
    deleteDiaryItem: PropTypes.func.isRequired
  })
};

export default ItemActions;
