import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { currencySymbol } from '../../../utils/currencyHelpers';
import { amountWithCommas } from '../../../utils/currencyHelpers';
import TotalsPeriodSelector from './TotalsPeriodSelector';

class Totals extends Component {
  revenueCurrencies = period => {
    const arr = Array.from(
      new Set([
        ...this.props.accountingAssignments.byId.map(id =>
          this.props.accountingAssignments.records[id].payment_date &&
          new Date(this.props.accountingAssignments.records[id].payment_date) > period
            ? this.props.accountingAssignments.records[id].currency
            : null
        )
      ])
    );
    return arr.filter(currency => currency); //Remove null value
  };

  revenueByCurrency = (currency, period) => {
    return this.props.accountingAssignments.byId
      .map(id =>
        this.props.accountingAssignments.records[id].currency === currency &&
        this.props.accountingAssignments.records[id].payment_date &&
        new Date(this.props.accountingAssignments.records[id].payment_date) > period
          ? this.props.accountingAssignments.records[id].revenue
          : null
      )
      .reduce((a, b) => a + b, 0);
  };

  revenueByPeriod = period => {
    return (
      <div className="mt1-5">
        {this.revenueCurrencies(period).map(currency => {
          return (
            <h2 key={currency} className="totals-income-amount">
              <span className="totals-currency-symbol">{currencySymbol(currency)}</span>
              {amountWithCommas(this.revenueByCurrency(currency, period))}
            </h2>
          );
        })}
      </div>
    );
  };

  estimatedRevenueInSek = () => {};

  revenueInSEK = period => {
    return this.props.accountingAssignments.byId
      .map(id =>
        this.props.accountingAssignments.records[id].payment_date &&
        this.props.accountingAssignments.records[id].budget_sek > 0 &&
        new Date(this.props.accountingAssignments.records[id].payment_date) > period
          ? this.props.accountingAssignments.records[id].budget_sek
          : null
      )
      .reduce((a, b) => a + b, 0);
  };

  pendingCurrencies = () => {
    const arr = Array.from(
      new Set([
        ...this.props.accountingAssignments.byId.map(id =>
          !this.props.accountingAssignments.records[id].payment_date
            ? this.props.accountingAssignments.records[id].currency
            : null
        )
      ])
    );
    return arr.filter(currency => currency); //Remove null value
  };

  pendingByCurrency = currency => {
    const total = this.props.accountingAssignments.byId
      .map(id =>
        this.props.accountingAssignments.records[id].currency === currency &&
        !this.props.accountingAssignments.records[id].payment_date
          ? this.props.accountingAssignments.records[id].revenue
          : null
      )
      .reduce((a, b) => a + b, 0);
    return total;
  };

  render() {
    const period = new Date(this.props.accountingAssignments.incomePeriod);
    return (
      <div className="totals">
        <h1 className="h1-5">Income</h1>
        <TotalsPeriodSelector />

        {this.revenueCurrencies(period).length === 0 ? (
          <h4 className="mt2">No income for this period</h4>
        ) : (
          <React.Fragment>
            {this.revenueByPeriod(period)}

            <div className="totals-line" />
            <h2 className="totals-income-amount">
              <span className="totals-currency-symbol">{currencySymbol('SEK')}</span>
              {amountWithCommas(this.revenueInSEK(period))}
            </h2>
          </React.Fragment>
        )}

        {this.pendingCurrencies().length === 0 ? null : (
          <React.Fragment>
            <h2 className="mt3 mb0-5">Pending</h2>
            {this.pendingCurrencies().map(currency => {
              return (
                <h3 key={currency} className="pending-income-amount">
                  <span className="pending-currency-symbol">{currencySymbol(currency)}</span>
                  {amountWithCommas(this.pendingByCurrency(currency))}
                </h3>
              );
            })}
          </React.Fragment>
        )}
      </div>
    );
  }
}

Totals.propTypes = {
  accountingAssignments: PropTypes.shape({
    records: PropTypes.object.isRequired,
    byId: PropTypes.array.isRequired,
    incomePeriod: PropTypes.instanceOf(Date)
  })
};

const mapStateToProps = accountingAssignments => accountingAssignments;

export default connect(mapStateToProps)(Totals);
