import React from 'react';

interface Props {
  name: string;
  ratingValue: number;
  lowestValue: number;
  numberOfDots: number;
  disabledDots?: number;
  onClick: any;
}

const interactiveRatings: React.FunctionComponent<Props> = props => {
  const { name, ratingValue, lowestValue, numberOfDots, disabledDots = 0, onClick } = props;

  const highestValue = lowestValue + numberOfDots - 1;

  // Translate the rating value into points
  const ratingValueToPoints = (ratingValue: number) => {
    return ratingValue + 1 - lowestValue;
  };

  // Create and return an array of full points: ●
  const fullPoints = () => {
    if (disabledDots >= 10) {
      return;
    }
    return Array(ratingValueToPoints(ratingValue))
      .fill('')
      .map((_, i) => {
        const ratingPointValue = i + lowestValue;
        return (
          <button
            title={'rating'}
            className={'rating-button no-btn'}
            name={name}
            value={ratingPointValue}
            key={ratingPointValue}
            onClick={e => onClick(e)}
          >
            <span className={'rating-point full'} />
          </button>
        );
      });
  };

  // Create and return an array of empty points: ○
  const emptyPoints = () => {
    if (disabledDots >= 10) {
      return;
    }
    return Array(highestValue - ratingValue - disabledDots)
      .fill('')
      .map((_, i) => {
        const ratingPointValue = i + lowestValue + ratingValueToPoints(ratingValue);
        return (
          <button
            title={'rating'}
            className={'rating-button no-btn'}
            name={name}
            value={ratingPointValue}
            key={ratingPointValue}
            onClick={e => onClick(e)}
          >
            <span className={'rating-point empty'} />
          </button>
        );
      });
  };
  // Create and return an array of grayed out points: ○
  const disabledPoints = () => {
    return Array(disabledDots)
      .fill('')
      .map((_, i) => {
        return (
          <div title={'rating'} className={'rating-button no-btn dib'} key={i}>
            <span className={'rating-point disabled'} />
          </div>
        );
      });
  };

  return (
    <span>
      <span>{fullPoints()}</span>
      <span>{emptyPoints()}</span>
      <span>{disabledPoints()}</span>
    </span>
  );
};

export default interactiveRatings;
