export const numberToTwoDigitsNumber = n => {
  return ('0' + n).slice(-2);
};

export const dateYesterday = () => {
  const date = new Date();
  return date.setDate(date.getDate() - 1);
};

export const dateFirstDayThisQuarter = () => {
  const date = new Date();
  const quarter = Math.floor(date.getMonth() / 3);
  return new Date(date.getFullYear(), quarter * 3, 1);
};

export const dateFirstDayLastQuarter = () => {
  const date = new Date();
  const quarter = Math.floor(date.getMonth() / 3);
  return new Date(date.getFullYear(), quarter * 3, 1);
};

export const dateFirstDayOfNMonthsAgo = n => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - n, 1);
};

export const dateFirstDayOfNYearsAgo = n => {
  const date = new Date();
  return new Date(date.getFullYear() - n, 0, 1);
};

export const toMonthDayCommaYear = date => {
  date = new Date(date);
  let dateArr = date
    .toDateString()
    .split(' ')
    .slice(1);
  dateArr[1] = dateArr[1] + ',';
  return dateArr.join(' ');
};

export const daysFromNow = date => {
  const today = new Date();
  const dateToCompare = new Date(date);
  const timeDifference = Math.abs(dateToCompare.getTime() - today.getTime());
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return daysDifference;
};

export const dateToYearDashMonthDashDay = date => {
  const dateObj = new Date(date);
  const month = numberToTwoDigitsNumber(dateObj.getUTCMonth() + 1);
  const day = numberToTwoDigitsNumber(dateObj.getUTCDate());
  const year = dateObj.getUTCFullYear();

  return year + '-' + month + '-' + day;
};
