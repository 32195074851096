// import axios from 'axios';

// export const getExchangeRates = async (amount, date, currency1, currency2) => {
//   const reqObj = {
//     amount,
//     date,
//     currency1,
//     currency2
//   };
//   try {
//     const res = await axios.post('/api/currencies/', reqObj);
//     return await res.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const currencySymbol = symbol => {
  switch (symbol) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'CAD':
      return 'C$';
    case 'AUD':
      return 'A$';
    case 'SEK':
      return 'SEK';
    default:
      return symbol;
  }
};

export const amountWithCommas = n => {
  return n.toLocaleString();
};
