import axios from 'axios';
import {
  FETCH_LOG_ITEMS,
  ADD_LOG_ITEM,
  EDIT_LOG_ITEM,
  DELETE_LOG_ITEM,
  FETCH_LOG_TIME_LOGGED_TODAY,
  UPDATE_LOG_TIME_LOGGED_TODAY
} from './types';

// ----------------------------------------------------------------------
// ---------------------- BASIC LOG ACTIONS -----------------------------
// ----------------------------------------------------------------------

export const fetchLogItems = () => async dispatch => {
  try {
    const res = await axios.get('/api/log/');
    dispatch({ type: FETCH_LOG_ITEMS, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const addLogItem = values => async dispatch => {
  try {
    const res = await axios.post('/api/log/', values);
    dispatch({ type: ADD_LOG_ITEM, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const editLogItem = values => async dispatch => {
  try {
    const res = await axios.put('/api/log/', values);
    dispatch({ type: EDIT_LOG_ITEM, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteLogItem = id => async dispatch => {
  try {
    await axios.delete(`/api/log/${id}`);
    dispatch({ type: DELETE_LOG_ITEM, payload: id });
  } catch (error) {
    console.log(error);
  }
};

// ----------------------------------------------------------------------
// ---------------------- EXTRA LOG ACTIONS -----------------------------
// ----------------------------------------------------------------------

export const fetchLoggedTimeToday = () => async dispatch => {
  try {
    const res = await axios.get('/api/log/time-logged-today');
    dispatch({ type: FETCH_LOG_TIME_LOGGED_TODAY, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const updateLoggedTimeToday = () => dispatch => {
  dispatch({ type: UPDATE_LOG_TIME_LOGGED_TODAY, payload: null });
};
