import React, { useState, useEffect } from 'react';
import P5Wrapper from 'react-p5-wrapper';
import { connect } from 'react-redux';

import { IGuestbook, IDrawing } from './IGuestbook';
import { deleteGuestbookItem } from '../../actions/guestbook';

import ItemActions from './ItemActions';

interface IProps {
  id: number;
  guestbook: IGuestbook;
  deleteGuestbookItem: any;
}

const sketch = (p: any) => {
  // Initialize and defaults
  let drawing: IDrawing;
  let readyFired: boolean = false;
  let playFired: boolean = false;
  let setPlay: any;

  // p5Wrapper method that will be triggered on new props
  // https://github.com/and-who/react-p5-wrapper#usage
  p.myCustomRedrawAccordingToNewPropsHandler = (props: any) => {
    if (props.drawing) {
      drawing = props.drawing;
    }
    if (props.ready === true && !readyFired && !playFired) {
      console.log('READY FIRED');
      readyFired = true;
      setTimeout(() => displayDrawing(), 500);
    }
    if (props.playDrawing === true && !playFired) {
      playFired = true;
      play();
      console.log('PLAY FIRED!');
    }
    if (props.setPlayDrawing) {
      setPlay = props.setPlayDrawing;
      console.log('SET PLAY!!');
    }
    // if (props.clearDrawing === true) {
    //   clear();
    // }
  };

  p.setup = () => {
    setTimeout(() => init(), 100);
    const init = () => {
      p.createCanvas(drawing.width / drawing.resolution, drawing.height / drawing.resolution);
      // p.createCanvas(1200 / 2, 800 / 2);
      p.canvas.className = 'drawing';
      p.pixelDensity(drawing.resolution);
      p.background(drawing.background);
      p.noLoop();
    };
  };

  // setTimeout(() => play(), 500);

  const displayDrawing = () => {
    const drawingFlat = drawing.drawing.flat();
    for (const line of drawingFlat) {
      p.stroke(line.color);
      p.strokeWeight(line.size);
      p.line(line.mouseX, line.mouseY, line.pmouseX, line.pmouseY);
    }
  };

  // const clear = () => {
  //   p.clear();
  // };

  // p.draw = () => {
  //   p.clear();
  // };

  let counter: number = 0;
  // p.draw = () => {
  //   console.log('Drawing is running');
  //   const drawingFlat = drawing.drawing.flat();
  //   if (counter < drawingFlat.length) {
  //     p.stroke(drawingFlat[counter].color);
  //     p.strokeWeight(drawingFlat[counter].size);
  //     p.line(
  //       drawingFlat[counter].mouseX,
  //       drawingFlat[counter].mouseY,
  //       drawingFlat[counter].pmouseX,
  //       drawingFlat[counter].pmouseY
  //     );
  //     counter++;
  //   } else {
  //     playFired = false;
  //     setPlay(false);
  //     p.noLoop();
  //     counter = 0;
  //     // p.clear();
  //   }
  // };

  const play = () => {
    let counter: number = 0;
    p.clear();
    p.loop();
    p.draw = () => {
      console.log('Drawing is running');
      const drawingFlat = drawing.drawing.flat();
      if (counter < drawingFlat.length) {
        p.stroke(drawingFlat[counter].color);
        p.strokeWeight(drawingFlat[counter].size);
        p.line(
          drawingFlat[counter].mouseX,
          drawingFlat[counter].mouseY,
          drawingFlat[counter].pmouseX,
          drawingFlat[counter].pmouseY
        );
        counter++;
      } else {
        playFired = false;
        setPlay(false);
        p.noLoop();
        // p.clear();
      }
    };
    // playFired = false;
    // readyFired = false;
  };
};

const Item: React.FunctionComponent<IProps> = props => {
  const id = props.id;
  const { fav, submitted, submission_approved, submitter } = props.guestbook.records[id];
  const [ready, setReady] = useState(false);
  const [play, setPlay] = useState(false);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => console.log('PLAY?', id, play));

  const handlePlay = () => {
    setPlay(true);
    // setTimeout(() => setPlay(false), 500);
  };

  const handleDelete = async (id: number) => {
    await props.deleteGuestbookItem(id);
  };

  return (
    <React.Fragment>
      <P5Wrapper
        sketch={sketch}
        drawing={props.guestbook.records[id]}
        ready={ready}
        playDrawing={play}
        setPlayDrawing={setPlay}
        clearDrawing={clear}
      />
      {submitted ? (
        <div className="submitter">&mdash; Submitted by {submitter ? submitter : 'anonymous'}.</div>
      ) : null}
      <button onClick={() => handlePlay()}>{play ? 'PLAYING...' : 'PLAY'}</button>
      <ItemActions id={id} />
    </React.Fragment>
  );
};

const mapStateToProps = ({ guestbook }: IProps) => {
  return { guestbook };
};

const mapDispatchToProps = {
  deleteGuestbookItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Item);
