import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Transition } from 'react-spring/renderprops';

import Dots from '../dataVisualization/Dots';

class HeaderDots extends Component {
  state = {
    hoverOn: false
  };

  outPutDots = () => {
    const usedTime = this.props.log.usedTimeToday;
    const timeLeft = 10 - usedTime;

    if (timeLeft < 0) {
      return <div>Error, under 0!</div>;
    } else if (timeLeft === 0) {
      return <div className="header-dots">&#9675;</div>;
    } else if (timeLeft <= 3) {
      return <div className="header-dots low">{Dots(timeLeft)}</div>;
    } else {
      return <div className="header-dots">{Dots(timeLeft)}</div>;
    }
  };

  hoverHandler = () => {
    this.setState({ hoverOn: !this.state.hoverOn });
  };

  outputNumberOnHover = () => {
    return <div className="header-dots-hover mr0-5">{10 - this.props.log.usedTimeToday}</div>;
  };

  render() {
    const hover = this.state.hoverOn;
    return (
      <div className="right" onMouseEnter={this.hoverHandler} onMouseLeave={this.hoverHandler}>
        <Transition
          config={{ duration: 100 }}
          items={hover}
          from={{ opacity: 0, transform: 'translate3d(0.5rem, 0, 0)' }}
          enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
          leave={{ opacity: 0, transform: 'translate3d(0.5rem, 0, 0)' }}
        >
          {hover => (hover ? props => <div style={props}>{this.outputNumberOnHover()}</div> : null)}
        </Transition>

        {this.outPutDots()}
      </div>
    );
  }
}

const mapStateToProps = log => log;

export default connect(
  mapStateToProps,
  {}
)(HeaderDots);
