import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import { GET_ERRORS, SET_CURRENT_USER } from './types';

export const login = values => async dispatch => {
  try {
    const res = await axios.post('/api/users/login/', values);

    const { token } = res.data;

    //Save to LocalStorage
    localStorage.setItem('ioToken', token);

    //Set token to Auth Header
    setAuthToken(token);

    //Decode token to get user data
    const decoded = jwt_decode(token);

    //Set current user
    dispatch(setCurrentUser(decoded));

    //
  } catch (err) {
    console.log(err);
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

export const register = values => async dispatch => {
  try {
    await axios.post('/api/users/register/', values);
  } catch (err) {
    console.log(err);
    dispatch({ type: GET_ERRORS, payload: err.response.data });
  }
};

// Set Logged user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('ioToken');

  // Remove auth header for future requests
  setAuthToken(false);

  // Set current user to empty obj which
  // will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
