import React from 'react';

interface Props {
  data: {
    area: string;
    time: number;
    percentage: string;
  }[];
}

interface Item {
  percentage: string;
  area: string;
}

const HorizontalBar: React.FunctionComponent<Props> = props => {
  const { data } = props;

  const colors = [
    'var(--main-color)',
    'var(--red)',
    'var(--green)',
    'var(--black)',
    'var(--yellow)',
    'var(--gray5)'
  ];

  const generateBars = () => {
    return data.map((i: Item, index: number) => {
      const barWidth = {
        width: i.percentage + '%'
      };
      const color = {
        backgroundColor: colors[index]
      };
      return (
        <div key={index} style={barWidth}>
          <div className="single-horizontal-bar" style={color} />
          <div className="single-horizontal-bar-name mt1">
            <span>{i.area}</span>
            <span className="c-gray6 ml0-5 fw700">{Math.round(Number(i.percentage))}%</span>
          </div>
        </div>
      );
    });
  };

  return <div className="single-horizontal-bars-chart">{generateBars()}</div>;
};

export default HorizontalBar;
