import PropTypes from 'prop-types';

// It expects two arguments.
// 1. An array the name fields
// 2. State to compare those names with the values.
//
// Example:
// const validateArr = ['date', 'area', 'project'];
// if (!isEmpty(validate(validateArr, this.state))) {
//   return;
// }

export default function validate(fields, values) {
  const errors = {};

  fields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'is empty';
      document.querySelector(`.${field}.error`).textContent = 'Required';
    }
  });

  return errors;
}

validate.PropTypes = {
  fields: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired
};
