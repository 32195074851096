import React, { Component } from 'react';

import HeaderDots from './HeaderDots';
import HeaderNav from './HeaderNav';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <HeaderNav />
        <HeaderDots />
      </div>
    );
  }
}

export default Header;
