import Fields from './Fields';

export default function validate(values) {
  const errors = {};

  Fields.forEach(field => {
    if (field.isRequired && !values[field.name]) {
      errors[field.name] = 'is empty';
      document.querySelector(`.${field.name}.error`).textContent = 'Required';
    }
  });

  return errors;
}
