export const isEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.replace(/\s/g, '').length === 0)
  );
};

export const normalizeArr = arr => {
  const ratio = Math.max(...arr) / 100;
  return arr.map(item => Math.round(item / ratio));
};

export const trimString = (str, numCharacters) => {
  if (str.length >= numCharacters) {
    return str.substring(0, numCharacters).trim() + '...';
  } else {
    return str;
  }
};

export const arrToArrOfPercentages = arr => {
  const max = arr.reduce((a, b) => a + b, 0);
  return arr.map(item => ((100 * item) / max).toFixed(2));
};
