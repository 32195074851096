import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import axios from 'axios';

import { editLogItem, fetchLogItems, fetchLoggedTimeToday } from '../../actions/log';
import { closeModal } from '../../actions/modal';

import { ILog } from './ILog';
import { IModal } from '../IModal';

import Suggestions from '../inputs/Suggestions';
import Rating from '../inputs/RatingInteractive';
import RatingTime from '../inputs/RatingTime';
import Input from '../inputs/Input';
import Autocomplete from '../inputs/Autocomplete';

import { isEmpty } from '../../utils/dataHelpers';
import { getLastNumberInUrl } from '../../utils/helpers';
import { dateToYearDashMonthDashDay } from '../../utils/timeHelpers';
import validate from '../inputs/InputsValidation';

interface Props {
  fetchLogItems(): Object;
  editLogItem(obj: Object): void;
  fetchLoggedTimeToday(): void;
  closeModal(): any;
  modal: IModal;
  log: ILog;
  location: {
    id: number;
  };
}

interface State {
  id?: number;
  original_date: string;
  date: string;
  area: string;
  project: string;
  time: number;
  original_time: number;
  mood_before: number;
  mood_after: number;
  logged_time: number;
  redirectAfterSubmit: boolean;
  area_suggestions: string[];
  project_suggestions: string[];
  errors: Object;
  httpError: string;
}

class EditItem extends Component<Props, State> {
  state = {
    id: undefined,
    original_date: '',
    date: '',
    area: '',
    project: '',
    original_time: 1,
    time: 1,
    mood_before: 0,
    mood_after: 0,
    logged_time: 0,
    redirectAfterSubmit: false,
    area_suggestions: [],
    project_suggestions: [],
    errors: {},
    httpError: ''
  };

  componentDidMount() {
    this.handleInitialize();
  }

  async fetchState() {
    //If id is not in props.location get it from URL
    const id = this.props.location.id || getLastNumberInUrl();

    //If id is not in props.location, means there is no state. Fetch it
    if (!this.props.location.id) {
      await this.props.fetchLogItems();
    }

    //Match id and get Item Data from the Store via props.
    const itemData = this.props.log.records[id];

    //Add item data to State
    this.setState({
      ...itemData,
      date: dateToYearDashMonthDashDay(itemData.date),
      original_date: dateToYearDashMonthDashDay(itemData.date),
      original_time: itemData.time,
      id
    });
  }

  async handleInitialize() {
    try {
      await this.fetchState();
      await Promise.all([
        this.fetchAreaSuggestions(),
        this.fetchProjectSuggestions(),
        this.initialFetchLoggedTime(this.state.date)
      ]);
    } catch (err) {
      console.error(err);
      this.setState({ httpError: err });
      throw err;
    }
  }

  async fetchAreaSuggestions() {
    const res = await axios.get('/api/log/areas/');
    this.setState({ area_suggestions: res.data });
  }

  async fetchProjectSuggestions() {
    const res = await axios.get('/api/log/projects/');
    this.setState({ project_suggestions: res.data });
  }

  private initialFetchLoggedTime = async (date: string) => {
    const res = await axios.get(`/api/log/time-logged-date?date=${date}`);
    const fetchedLoggedTime = res.data;
    const adjustedLoggedTime = fetchedLoggedTime - this.state.time;
    this.setState({ logged_time: adjustedLoggedTime >= 0 ? adjustedLoggedTime : 0 }); // Prevent negative values
  };

  private onRatingClick = (e: React.MouseEvent<HTMLInputElement>): void => {
    const newRatingValue = Number(e.currentTarget.value);
    const field = e.currentTarget.name;

    if (field === 'time') {
      this.updateTime(newRatingValue);
    } else {
      this.setState({ [field]: newRatingValue } as any);
    }
  };

  private updateTime = (time: number) => {
    const totalTime = this.state.logged_time + time;
    if (totalTime > 10) {
      alert('Too much logged time!');
      return;
    } else {
      this.setState({ time: time });
    }
  };

  private updateLoggedTimeOnDateChange = async (newDate: string) => {
    try {
      let newTime = this.state.time;

      // 1. Get logged time for the new date
      const res = await axios.get(`/api/log/time-logged-date?date=${newDate}`);
      let loggedTimeNewDate = await res.data;

      // 2. Adjust Time
      // 2.1 Remove extra Dots
      const newTotalTime = loggedTimeNewDate + newTime;
      if (newTotalTime > 10) {
        const toDeduct = newTotalTime - 10;
        newTime = newTime - toDeduct;
      }

      // 2.2 If editing same date, subtract original time
      if (newDate === this.state.original_date) {
        const adjustedLoggedTime = loggedTimeNewDate - this.state.original_time;
        adjustedLoggedTime >= 0
          ? (loggedTimeNewDate = adjustedLoggedTime)
          : (loggedTimeNewDate = 0);
      }

      // 3. Save
      this.setState({ date: newDate, time: newTime, logged_time: loggedTimeNewDate });
    } catch (err) {
      console.log(err);
      this.setState({ httpError: err });
      throw err;
    }
  };

  private onDateChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newDate = e.target.value;
    if (newDate) {
      this.updateLoggedTimeOnDateChange(newDate);
    }
  };

  private onAutocompleteChange = (inputName: string, value: string) => {
    this.setState({ [inputName]: value } as any);
  };

  private onSuggestionClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const suggestionFor: string = e.target.getAttribute('data-suggestionfor')!;
    const suggestion = e.target.getAttribute('data-suggestion');
    this.setState({ [suggestionFor]: suggestion } as any);
  };

  private onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { date, area, project, time, mood_before, mood_after } = this.state;

    //Validate Form
    const validateArr = ['date', 'area', 'project'];
    if (!isEmpty(validate(validateArr, this.state))) {
      return;
    }

    const logItemObj = {
      id: this.state.id,
      date,
      area: area.trim().toLowerCase(), // Remove white spaces and make it lowercase
      project: project.trim().toLowerCase(), // Remove white spaces and make it lowercase
      time,
      mood_before,
      mood_after
    };

    // Save to DB
    this.saveToDB(logItemObj);
  };

  private saveToDB = async (logItemObj: Object) => {
    // Save to DB, update time Left and Redirect.
    try {
      await this.props.editLogItem(logItemObj);
      await this.props.fetchLoggedTimeToday();
      this.redirectHandler();
    } catch (err) {
      console.log(err);
      this.setState({ httpError: err });
      throw err;
    }
  };

  private redirectHandler = () => {
    // If no modal, redirect.
    if (this.props.modal.showModal === '') {
      this.setState({ redirectAfterSubmit: true });
      // If modal, don't redirect, but close the modal.
    } else if (this.props.modal.showModal === 'log') {
      this.props.closeModal();
    }
  };

  public render(): JSX.Element {
    if (this.state.redirectAfterSubmit) {
      return <Redirect to="/log" />;
    }
    return (
      <div className="maxWidth">
        <h1 className="h1-5 mb2">Edit Log</h1>

        <div>
          <h3>
            <span>Time: </span>
            <RatingTime
              name={'time'}
              ratingValue={this.state.time}
              lowestValue={1}
              numberOfDots={10}
              disabledDots={this.state.logged_time}
              onClick={this.onRatingClick}
            />
          </h3>
          <h3>
            <span>Mood Before: </span>
            <Rating
              name={'mood_before'}
              ratingValue={this.state.mood_before}
              lowestValue={-2}
              numberOfPoints={5}
              onClick={this.onRatingClick}
            />
          </h3>
          <h3 className="mb2">
            <span>Mood After: </span>
            <Rating
              name={'mood_after'}
              ratingValue={this.state.mood_after}
              lowestValue={-2}
              numberOfPoints={5}
              onClick={this.onRatingClick}
            />
          </h3>
        </div>

        <form className="minimal-form-items" onSubmit={this.onSubmit}>
          <div className="minimal-form-column">
            <Input
              label={'Date'}
              name={'date'}
              type={'date'}
              isRequired={true}
              value={this.state.date}
              onChange={this.onDateChange}
            />

            <Autocomplete
              suggestions={this.state.area_suggestions}
              label={'Area'}
              name={'area'}
              type={'text'}
              isRequired={true}
              value={this.state.area}
              passStateToParent={this.onAutocompleteChange}
              customClass={'mb0'}
            />

            <div className="mt0-25 mb2-5">
              <Suggestions
                suggestionFor={'area'}
                suggestions={this.state.area_suggestions}
                onClick={this.onSuggestionClick}
              />
            </div>

            <Autocomplete
              suggestions={this.state.project_suggestions}
              label={'Project'}
              name={'project'}
              type={'text'}
              isRequired={true}
              value={this.state.project}
              passStateToParent={this.onAutocompleteChange}
              customClass={'mb0'}
            />
            <div className="mt0-25 mb2-5">
              <Suggestions
                suggestionFor={'project'}
                suggestions={this.state.project_suggestions}
                onClick={this.onSuggestionClick}
              />
            </div>

            <button type="submit" className="mt1">
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ log, modal }: Props) => {
  return { log, modal };
};

const mapDispatchToProps = { fetchLogItems, editLogItem, fetchLoggedTimeToday, closeModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditItem);
