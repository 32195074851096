import React from 'react';

import Fields from './Fields';

const RenderFields = ({ fieldStructure }) => {
  const renderMainFields = () => {
    return Fields.filter(field => field.name === 'division' || field.name === 'period').map(
      field => {
        return fieldStructure(field);
      }
    );
  };
  const renderExtraFields = () => {
    return Fields.filter(field => field.name === 'start' || field.name === 'end').map(field => {
      return fieldStructure(field);
    });
  };

  return (
    <React.Fragment>
      <div className="accounting-column">{renderMainFields()}</div>
      <div className="accounting-column">{renderExtraFields()}</div>
      <div className="accounting-column">
        <button type="submit" className="mt1">
          Save
        </button>
      </div>
    </React.Fragment>
  );
};

export default RenderFields;
