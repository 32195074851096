import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { login } from '../../actions/auth';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      errors: {},
      redirectAfterSubmit: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.auth.isAuthenticated) {
  //     return <Redirect to="/accounting" />;
  //   }
  //   if (nextProps.erros) {
  //     this.setState({ errors: nextProps.errors });
  //   }
  // }

  async onSubmit(e) {
    e.preventDefault();

    const user = {
      username: this.state.username,
      password: this.state.password
    };

    try {
      await this.props.login(user);
      this.setState({ redirectAfterSubmit: true });
    } catch (err) {
      console.log(err);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    if (this.state.redirectAfterSubmit) {
      return <Redirect to="/accounting" />;
    }

    return (
      <div className="login">
        <form onSubmit={this.onSubmit}>
          <div className="mb1">
            <input
              type="text"
              placeholder="Username"
              name="username"
              value={this.state.username}
              onChange={this.onChange}
            />
            {errors.username && <div>{errors.username}</div>}
          </div>
          <div className="mb1">
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={this.state.password}
              onChange={this.onChange}
            />
            {errors.password && <div>{errors.password}</div>}
          </div>
          <input type="submit" className="button" />
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
