import React from 'react';

const Field = ({
  input,
  name,
  value,
  label,
  id,
  type,
  isSelect,
  selectOptions,
  selected,
  checked,
  isRequired,
  onChange
}) => {
  const outputField = () => {
    return (
      <div className={name}>
        <label htmlFor={id} className={`minimal-form ${name}`}>
          {label}
        </label>
        <span className={`dib error minimal-form ml0-5 hidden ${name}`}>
          {isRequired ? 'Required' : ''}
        </span>

        {isSelect === true ? ( // If is select
          <React.Fragment>
            <select
              name={name}
              onChange={onChange}
              id={id}
              value={selected}
              className="minimal-form"
            >
              {selectOptions.map(option => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </React.Fragment>
        ) : (
          // Any other fields
          <React.Fragment>
            <input
              {...input}
              name={name}
              placeholder={label}
              id={id}
              type={type}
              value={value}
              checked={checked}
              onChange={onChange}
              className="accounting-field minimal-form"
              required={isRequired}
            />
          </React.Fragment>
        )}
      </div>
    );
  };

  return <React.Fragment>{outputField()}</React.Fragment>;
};

export default Field;
