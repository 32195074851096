import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchGuestbookItems } from '../../actions/guestbook';
import { IGuestbook } from './IGuestbook';

import Item from './Item';

interface Props {
  fetchGuestbookItems(): Promise<void>;
  guestbook: IGuestbook;
}

const AllItems: React.FunctionComponent<Props> = props => {
  useEffect(() => {
    props.fetchGuestbookItems().catch(err => console.error(err));
  }, []);

  return (
    <React.Fragment>
      <h1 className="c-black mb2 h1-5">Guestbook</h1>
      <ul className="guestbook-items">
        {props.guestbook.byId.map(id => {
          return <Item key={id} id={id} />;
        })}
      </ul>
    </React.Fragment>
  );
};

function mapStateToProps({ guestbook }: Props) {
  return { guestbook };
}

const mapDispatchToProps = { fetchGuestbookItems };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllItems);
