import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

import { fetchAssignmentsItems, editAssignmentItem } from '../../../actions/accounting';
import RenderFields from './forms/RenderFields';
import Field from './forms/FieldStructure';
import {
  getNextInvoiceNumber,
  updateNextInvoiceNumber,
  initialDateInputColor,
  updateDateInputColor
} from './forms/FormHelpers';

import validate from './forms/FormValidation';
import { isEmpty } from '../../../utils/dataHelpers';
import { getLastNumberInUrl } from '../../../utils/helpers';

class EditItem extends Component {
  state = {
    errors: {},
    redirectAfterSubmit: false
  };

  componentDidMount() {
    this.handleInitialize();
  }

  async handleInitialize() {
    //If id is not in props.location get it from URL
    const id = this.props.location.id || getLastNumberInUrl();

    //If id is not in props.location, means there is no state. Fetch it
    if (!this.props.location.id) {
      await this.props.fetchAssignmentsItems();
    }

    //Match id and get Item Data from the Store via props.
    const itemData = this.props.accountingAssignments.records[id];

    //Add item data to State
    this.setState({
      ...itemData,
      id
    });

    await initialDateInputColor(id, this.props, this.state);

    console.log('STATE: ', this.state);
  }

  onSubmit = e => {
    e.preventDefault();

    const {
      client,
      description,
      contact,
      is_licensing,
      currency,
      budget,
      vat_percentage,
      expenses,
      invoice_number,
      invoice_prefix,
      invoice_date,
      payment_date
    } = this.state;

    //Validate Form
    if (!isEmpty(validate(this.state))) {
      console.log('ERRORS!', validate(this.state));
      return;
    }

    const accountingItemObj = {
      id: this.state.id,
      client,
      description: description || null,
      contact: contact || null,
      is_licensing: is_licensing,
      currency,
      budget,
      vat_percentage: vat_percentage || 0,
      expenses: expenses || 0,
      invoice_number: invoice_number || null,
      invoice_prefix: invoice_prefix || null,
      invoice_date: invoice_date || null,
      payment_date: payment_date || null
    };

    //Save to DB
    this.props
      .editAssignmentItem(accountingItemObj)
      .then(() => {
        console.log(accountingItemObj);
        //Redirect
        this.setState({ redirectAfterSubmit: true });
      })
      .catch(err => console.log(err));
  };

  onChange = e => {
    // Add Required span when field is empty
    if (e.target.type !== 'checkbox') {
      if (e.target.required && !isEmpty(e.target.value)) {
        document.querySelector(`.${e.target.name}.error`).classList.add('hidden');
      } else {
        document.querySelector(`.${e.target.name}.error`).classList.remove('hidden');
      }
    }

    // Update the invoice_number placeholder when adding an invoice prefix
    if (e.target.name === 'invoice_prefix') {
      // Get invoice number matching the prefix
      const nextInvoice = updateNextInvoiceNumber(e.target.value, this.props);

      // Set it as the invoice_number input placeholder
      document.querySelector('#invoice_number').placeholder = `Next #${nextInvoice}`;
    }

    // Ensure the correct value of the checkbox
    if (e.target.type === 'checkbox') {
      let checkboxVal = this.state[e.target.name];
      this.setState({ [e.target.name]: !checkboxVal });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

    //Update Date Input Placeholder color when empty
    if (e.target.type === 'date') {
      updateDateInputColor();
    }
  };

  fieldStructure = field => {
    return (
      <Field
        key={field.name}
        type={field.type}
        label={field.label}
        name={field.name}
        placeholder={field.label}
        id={field.name}
        onChange={this.onChange}
        value={this.state[field.name] ? this.state[field.name] : ''}
        isSelect={field.isSelect}
        selectOptions={field.selectOptions}
        selected={this.state.currency}
        checked={this.state[field.name] ? this.state[field.name] : false}
        isRequired={field.isRequired}
        nextInvoiceNumber={getNextInvoiceNumber(null, this.props)}
      />
    );
  };

  render() {
    if (this.state.redirectAfterSubmit) {
      return <Redirect to="/accounting" />;
    }
    return (
      <div className="maxWidth">
        <h1 className="h1-5 mb2">Edit</h1>
        <div className="accounting-items-form-container">
          <form className="accounting-items-form" onSubmit={this.onSubmit}>
            <RenderFields fieldStructure={this.fieldStructure} />
          </form>
        </div>
      </div>
    );
  }
}

EditItem.propTypes = {
  accountingAssignments: PropTypes.shape({
    records: PropTypes.object.isRequired,
    byId: PropTypes.array.isRequired
  }),
  fetchAssignmentsItems: PropTypes.func.isRequired,
  editAssignmentItem: PropTypes.func.isRequired
};

function mapStateToProps({ accountingAssignments }) {
  return { accountingAssignments };
}

export default connect(
  mapStateToProps,
  { fetchAssignmentsItems, editAssignmentItem }
)(EditItem);
