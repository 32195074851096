import { removeByKey } from '../utils/helpers';
import { dateFirstDayOfNMonthsAgo } from '../utils/timeHelpers';
import {
  FETCH_ACCOUNTING_ITEMS,
  DELETE_ACCOUNTING_ITEM,
  UPDATE_ASSIGNMENTS_TOTAL_PERIOD,
  SORT_ASSIGNMENTS,
  SORT_ORDER_ASSIGNMENTS
  // ADD_ACCOUNTING_ITEM,
  // EDIT_ACCOUNTING_ITEM,
} from '../actions/types';

const initialState = {
  byId: [],
  records: {},
  incomePeriod: dateFirstDayOfNMonthsAgo(0),
  sortBy: 'status',
  sortOrder: 'ASC'
};

function fetchAssignmentItems(state, records) {
  return {
    ...state,
    byId: Array.from(new Set([...state.byId, ...records.map(r => r.id)])),
    records: {
      ...state.records,
      ...records.reduce((obj, record) => ({ ...obj, [record.id]: record }), {})
    }
  };
}

function deleteAssignmentItem(state, id) {
  return {
    ...state,
    byId: state.byId.filter(i => i !== id),
    records: removeByKey(state.records, id)
  };
}

const updateAssignmentsTotals = (state, period) => {
  console.log('period: ', period);
  return {
    ...state,
    incomePeriod: period
  };
};

const sortAssignmentsBy = (state, sortBy) => {
  console.log('sort By: ', sortBy);
  const sortAssignments = () => {
    const assignments = state;

    // Create a map with the sorting parameter and id
    const mapWithSortParm = assignments.byId.map(id => {
      return { id, [sortBy]: assignments.records[id][sortBy] };
    });

    // Sort it by parameter
    return mapWithSortParm.sort(function(a, b) {
      return (a[sortBy] < b[sortBy]) - (a[sortBy] > b[sortBy]);
    });
  };
  return {
    ...state,
    byId: sortAssignments().map(i => i.id),
    sortBy: sortBy
  };
};

const sortAssignmentsOrder = (state, period) => {
  console.log('period: ', period);
  return {
    ...state,
    byId: state.byId.slice(0).reverse(),
    sortOrder: state.sortOrder === 'ASC' ? 'DESC' : 'ASC'
  };
};

// ------------ UNUSED ------------

// function addItem(state, item) {
//   return {
//     byId: [...state.byId, item.id],
//     records: {
//       ...state.records,
//       [item.id]: { item }
//     }
//   };
// }

// function editItem(state, item) {
//   return {
//     byId: [...state.byId],
//     records: {
//       ...state.records,
//       [item[1].id]: { item }
//     }
//   };
// }

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNTING_ITEMS:
      return fetchAssignmentItems(state, action.payload);
    // case ADD_ACCOUNTING_ITEM:
    //   return addItem(state, action.payload);
    // case EDIT_ACCOUNTING_ITEM:
    //   return editItem(state, action.payload);
    case DELETE_ACCOUNTING_ITEM:
      return deleteAssignmentItem(state, action.payload);
    case UPDATE_ASSIGNMENTS_TOTAL_PERIOD:
      return updateAssignmentsTotals(state, action.payload);
    case SORT_ASSIGNMENTS:
      return sortAssignmentsBy(state, action.payload);
    case SORT_ORDER_ASSIGNMENTS:
      return sortAssignmentsOrder(state, action.payload);
    default:
      return state;
  }
}
