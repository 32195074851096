import axios from 'axios';
import {
  FETCH_GUESTBOOK_ITEMS,
  DELETE_GUESTBOOK_ITEM
  // EDIT_GUESTBOOK_ITEM,
} from './types';

// ----------------------------------------------------------------------
// ---------------------- BASIC LOG ACTIONS -----------------------------
// ----------------------------------------------------------------------

export const fetchGuestbookItems = () => async dispatch => {
  try {
    const res = await axios.get('/api/guestbook/');
    dispatch({ type: FETCH_GUESTBOOK_ITEMS, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

// Delete Drawing
export const deleteGuestbookItem = id => async dispatch => {
  try {
    await axios.delete(`/api/guestbook/${id}`);
    dispatch({ type: DELETE_GUESTBOOK_ITEM, payload: id });
  } catch (error) {
    console.log(error);
  }
};

// export const editLogItem = values => async dispatch => {
//   try {
//     const res = await axios.put('/api/log/', values);
//     dispatch({ type: EDIT_LOG_ITEM, payload: res.data });
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const deleteLogItem = id => async dispatch => {
//   try {
//     await axios.delete(`/api/log/${id}`);
//     dispatch({ type: DELETE_LOG_ITEM, payload: id });
//   } catch (error) {
//     console.log(error);
//   }
// };
