import { isEmpty } from '../utils/dataHelpers';
import { SET_CURRENT_USER } from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {}
};

const setCurrentUser = (state, user) => {
  return {
    ...state,
    isAuthenticated: !isEmpty(user),
    user
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return setCurrentUser(state, action.payload);
    default:
      return state;
  }
}
