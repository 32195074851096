import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

import { addAssignmentItem, fetchAssignmentsItems } from '../../../actions/accounting';
import RenderFields from './forms/RenderFields';
import Field from './forms/FieldStructure';
import {
  getNextInvoiceNumber,
  updateNextInvoiceNumber,
  initialDateInputColor,
  updateDateInputColor
} from './forms/FormHelpers';
import { isEmpty } from '../../../utils/dataHelpers';
import validate from './forms/FormValidation';

class AddItem extends Component {
  state = {
    client: '',
    description: '',
    contact: '',
    is_licensing: false,
    currency: 'EUR',
    expenses: '',
    vat_percentage: '',
    invoice_number: '',
    invoice_prefix: '',
    invoice_date: '',
    payment_date: '',
    redirectAfterSubmit: false,
    errors: {},
    nextInvoiceNumber: 0
  };

  componentDidMount() {
    this.handleInitialize();
  }

  async handleInitialize() {
    await this.props.fetchAssignmentsItems();
    await initialDateInputColor(null, null, this.state);
  }

  onSubmit = e => {
    e.preventDefault();

    const {
      client,
      description,
      contact,
      is_licensing,
      currency,
      budget,
      expenses,
      vat_percentage,
      invoice_number,
      invoice_prefix,
      invoice_date,
      payment_date
    } = this.state;

    //Validate Form
    if (!isEmpty(validate(this.state))) {
      return;
    }

    const accountingItemObj = {
      client,
      description: description || null,
      contact: contact || null,
      is_licensing: is_licensing,
      currency,
      budget,
      expenses: expenses || 0,
      vat_percentage: vat_percentage || 0,
      invoice_number: invoice_number || null,
      invoice_prefix: invoice_prefix || null,
      invoice_date: invoice_date || null,
      payment_date: payment_date || null
    };

    //Save to DB
    this.props
      .addAssignmentItem(accountingItemObj)
      .then(() => {
        console.log(accountingItemObj);
        //Redirect
        this.setState({ redirectAfterSubmit: true });
      })
      .catch(err => console.log(err));
  };

  onChange = e => {
    // Add Required span when field is empty
    if (e.target.type !== 'checkbox') {
      if (e.target.required && !isEmpty(e.target.value)) {
        document.querySelector(`.${e.target.name}.error`).classList.add('hidden');
      } else {
        document.querySelector(`.${e.target.name}.error`).classList.remove('hidden');
      }
    }

    // Update the invoice_number placeholder when adding an invoice prefix
    if (e.target.name === 'invoice_prefix') {
      // Get invoice number matching the prefix
      const nextInvoice = updateNextInvoiceNumber(e.target.value, this.props);

      // Set it as the invoice_number input placeholder
      document.querySelector('#invoice_number').placeholder = `Next #${nextInvoice}`;
    }

    // Ensure the correct value of the checkbox
    if (e.target.type === 'checkbox') {
      let checkboxVal = this.state[e.target.name];
      this.setState({ [e.target.name]: !checkboxVal });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

    //Update Date Input Placeholder color when empty
    if (e.target.type === 'date') {
      updateDateInputColor();
    }
  };

  fieldStructure = field => {
    return (
      <Field
        key={field.name}
        type={field.type}
        label={field.label}
        name={field.name}
        placeholder={field.label}
        id={field.name}
        onChange={this.onChange}
        value={field.value}
        isSelect={field.isSelect}
        selectOptions={field.selectOptions}
        selected={this.state.currency}
        checked={this.state[field.name]}
        isRequired={field.isRequired}
        nextInvoiceNumber={getNextInvoiceNumber(null, this.props)}
      />
    );
  };

  render() {
    if (this.state.redirectAfterSubmit) {
      return <Redirect to="/accounting" />;
    }
    return (
      <div className="maxWidth">
        <h1 className="h1-5 mb2">Add</h1>
        <div className="accounting-items-form-container">
          <form className="accounting-items-form" onSubmit={this.onSubmit}>
            <RenderFields fieldStructure={this.fieldStructure} />
          </form>
        </div>
      </div>
    );
  }
}

AddItem.propTypes = {
  fetchAssignmentsItems: PropTypes.func.isRequired,
  addAssignmentItem: PropTypes.func.isRequired
};

const mapStateToProps = accounting => accounting;

export default connect(
  mapStateToProps,
  { fetchAssignmentsItems, addAssignmentItem }
)(AddItem);
