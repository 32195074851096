import { UPDATE_MODAL, CLOSE_MODAL } from './types';

// ----------------------------------------------------------------------
// -------------------- BASIC MODAL ACTIONS -----------------------------
// ----------------------------------------------------------------------

export const updateModal = modal => dispatch => {
  dispatch({ type: UPDATE_MODAL, payload: modal });
};

export const closeModal = () => dispatch => {
  dispatch({ type: CLOSE_MODAL, payload: null });
};
