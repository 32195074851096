import React from 'react';
import PropTypes from 'prop-types';

const displayRatings = ({ ratingValue, lowestValue, numberOfPoints }) => {
  const highestValue = lowestValue + numberOfPoints - 1;

  // Translate the rating value into points
  const ratingValueToPoints = ratingValue => {
    return ratingValue + 1 - lowestValue;
  };

  const fullPoints = () => {
    return Array(ratingValueToPoints(ratingValue))
      .fill()
      .map((_, i) => {
        return <span key={i}>&#9679;</span>;
      });
  };

  const emptyPoints = () => {
    return Array(highestValue - ratingValue)
      .fill()
      .map((_, i) => {
        return <span key={i + ratingValue}>&#9675;</span>;
      });
  };

  return (
    <span>
      <span>{fullPoints()}</span>
      <span>{emptyPoints()}</span>
    </span>
  );
};

displayRatings.propTypes = {
  ratingValue: PropTypes.number.isRequired,
  lowestValue: PropTypes.number.isRequired,
  numberOfPoints: PropTypes.number.isRequired
};

export default displayRatings;
