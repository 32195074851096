import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ name, value, label, id, isRequired, onChange, placeholder, customClass }) => {
  const inputStructure = () => {
    const onKeyUp = e => {
      // Unfocus the field
      if (e.key === 'Escape') {
        e.target.blur();
      }
    };

    return (
      <div className={`minimal-form-item ${name}${customClass ? ' ' + customClass : ''}`}>
        <label htmlFor={id || name} className={`minimal-form ${name}`}>
          {label}
        </label>
        <span className={`dib error minimal-form ml0-5 hidden ${name}`}>
          {isRequired ? 'Required' : ''}
        </span>
        <React.Fragment>
          <textarea
            name={name}
            id={id || name}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            className="minimal-form minimal-form-field textarea"
            required={isRequired}
            placeholder={placeholder || label}
          />
        </React.Fragment>
      </div>
    );
  };

  return <React.Fragment>{inputStructure()}</React.Fragment>;
};

Textarea.prototypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  customClass: PropTypes.string
};

export default Textarea;
