export const UPDATE_MODAL = 'UPDATE_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REGISTER = 'REGISTER';

export const FETCH_ACCOUNTING_ITEMS = 'FETCH_ACCOUNTING_ITEMS';
export const ADD_ACCOUNTING_ITEM = 'ADD_ACCOUNTING_ITEM';
export const EDIT_ACCOUNTING_ITEM = 'EDIT_ACCOUNTING_ITEM';
export const DELETE_ACCOUNTING_ITEM = 'DELETE_ACCOUNTING_ITEM';
export const UPDATE_ASSIGNMENTS_TOTAL_PERIOD = 'UPDATE_ASSIGNMENTS_TOTAL_PERIOD';
export const SORT_ASSIGNMENTS = 'SORT_ASSIGNMENTS';
export const SORT_ORDER_ASSIGNMENTS = 'SORT_ORDER_ASSIGNMENTS';

export const FETCH_IT_ITEMS = 'FETCH_IT_ITEMS';
export const UPDATE_IT_TOTAL_PERIOD = 'UPDATE_IT_TOTAL_PERIOD';

export const FETCH_NEXT_INVOICE_NUMBER = 'FETCH_NEXT_INVOICE_NUMBER';

export const GET_ERRORS = 'GET_ERRORS';

export const FETCH_LOG_ITEMS = 'FETCH_LOG_ITEMS';
export const ADD_LOG_ITEM = 'ADD_LOG_ITEM';
export const EDIT_LOG_ITEM = 'EDIT_LOG_ITEM';
export const DELETE_LOG_ITEM = 'DELETE_LOG_ITEM';
export const FETCH_LOG_TIME_LOGGED_TODAY = 'FETCH_LOG_TIME_LOGGED_TODAY';
export const UPDATE_LOG_TIME_LOGGED_TODAY = 'UPDATE_LOG_TIME_LOGGED_TODAY';

export const FETCH_DIARY_ITEMS = 'FETCH_DIARY_ITEMS';
export const ADD_DIARY_ITEM = 'ADD_DIARY_ITEM';
export const EDIT_DIARY_ITEM = 'EDIT_DIARY_ITEM';
export const DELETE_DIARY_ITEM = 'DELETE_DIARY_ITEM';

export const FETCH_GUESTBOOK_ITEMS = 'FETCH_GUESTBOOK_ITEMS';
export const EDIT_GUESTBOOK_ITEM = 'EDIT_GUESTBOOK_ITEM';
export const DELETE_GUESTBOOK_ITEM = 'DELETE_GUESTBOOK_ITEM';
