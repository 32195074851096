import { isEmpty } from '../../../../utils/dataHelpers';

export const getNextInvoiceNumber = (prefix = null, props) => {
  // If no prefix is provided, filter the invoices without prefix
  if (!prefix) {
    const invoices = props.accountingAssignments.byId
      .filter(id => props.accountingAssignments.records[id].invoice_prefix === null)
      .map(id =>
        props.accountingAssignments.records[id].invoice_number
          ? props.accountingAssignments.records[id].invoice_number
          : null
      );
    // Return the next invoice number without prefix
    return isEmpty(invoices) ? 1 : Math.max(...invoices) + 1;
  } else {
    // If prefix is provided, filter the invoices with that prefix
    const invoices = props.accountingAssignments.byId
      .filter(id => props.accountingAssignments.records[id].invoice_prefix === prefix)
      .map(id =>
        props.accountingAssignments.records[id].invoice_number
          ? props.accountingAssignments.records[id].invoice_number
          : null
      );

    // If there are no invoices with that prefix return 1,
    // Otherwise, return the next invoice number for that prefix
    return isEmpty(invoices) ? 1 : Math.max(...invoices) + 1;
  }
};

export const updateNextInvoiceNumber = (prefix, props) => {
  return getNextInvoiceNumber(prefix, props);
};

export const initialDateInputColor = (id, props, state) => {
  const invoiceDate = document.querySelector('#invoice_date');
  const paymentDate = document.querySelector('#payment_date');

  const invoice_date =
    props === null ? state.invoice_date : props.accountingAssignments.records[id].invoice_date;
  const payment_date =
    props === null ? state.payment_date : props.accountingAssignments.records[id].payment_date;

  if (invoice_date) {
    invoiceDate.classList.remove('empty');
  } else {
    invoiceDate.classList.add('empty');
  }

  if (payment_date) {
    paymentDate.classList.remove('empty');
  } else {
    paymentDate.classList.add('empty');
  }
};

export const updateDateInputColor = () => {
  const invoiceDate = document.querySelector('#invoice_date');
  const paymentDate = document.querySelector('#payment_date');

  invoiceDate.value === undefined || invoiceDate.value === ''
    ? invoiceDate.classList.add('empty')
    : invoiceDate.classList.remove('empty');

  paymentDate.value === undefined || paymentDate.value === ''
    ? paymentDate.classList.add('empty')
    : paymentDate.classList.remove('empty');
};
