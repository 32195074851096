import PropTypes from 'prop-types';

const shortcuts = (e, props) => {
  if (e.key === 'Escape') {
    // Close Modal
    props.closeModal();
  } else if (e.key === 'l' || e.key === 'L') {
    // Show Modal New Log
    props.updateModal('log');
  } else if (e.key === 'd' || e.key === 'D') {
    // Show Modal New Diary Entry
    props.updateModal('diary');
  }
};

shortcuts.propTypes = {
  e: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  props: PropTypes.shape({
    showModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
  })
};

export default shortcuts;
