import React from 'react';
import PropTypes from 'prop-types';

const interactiveRatings = ({ name, ratingValue, lowestValue, numberOfPoints, onClick }) => {
  //
  // The Onclick function will pass the event to the parent component
  // and the action will be handled there.
  //

  const highestValue = lowestValue + numberOfPoints - 1;

  // Translate the rating value into points
  const ratingValueToPoints = ratingValue => {
    return ratingValue + 1 - lowestValue;
  };

  // Create and return an array of full points: ●
  const fullPoints = () => {
    return Array(ratingValueToPoints(ratingValue))
      .fill()
      .map((_, i) => {
        const ratingPointValue = i + lowestValue;
        return (
          <button
            title={'rating'}
            className={'rating-button no-btn'}
            name={name}
            value={ratingPointValue}
            key={ratingPointValue}
            onClick={e => onClick(e)}
          >
            <span className={'rating-point full'} />
          </button>
        );
      });
  };

  // Create and return an array of empty points: ○
  const emptyPoints = () => {
    return Array(highestValue - ratingValue)
      .fill()
      .map((_, i) => {
        const ratingPointValue = i + lowestValue + ratingValueToPoints(ratingValue);
        return (
          <button
            title={'rating'}
            className={'rating-button no-btn'}
            name={name}
            value={ratingPointValue}
            key={ratingPointValue}
            onClick={e => onClick(e)}
          >
            <span className={'rating-point empty'} />
          </button>
        );
      });
  };

  return (
    <span>
      <span>{fullPoints()}</span>
      <span>{emptyPoints()}</span>
    </span>
  );
};

interactiveRatings.prototypes = {
  name: PropTypes.string.isRequired,
  ratingValue: PropTypes.number.isRequired,
  lowestValue: PropTypes.number.isRequired,
  numberOfPoints: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default interactiveRatings;
