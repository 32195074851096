import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  dateFirstDayOfNMonthsAgo,
  dateFirstDayThisQuarter,
  dateFirstDayOfNYearsAgo
} from '../../../utils/timeHelpers';
import { updateItTotalsPeriod } from '../../../actions/accounting';

class TotalsPeriodSelector extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      selectorValue: 'This Month'
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
      console.log('Show Menu');
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
      console.log('Close Menu');
    });
  }

  periodSelectorHandeler = e => {
    switch (e.target.value) {
      case 'THIS_MONTH':
        this.setState({
          selectorValue: 'This Month'
        });
        return this.props.updateItTotalsPeriod(dateFirstDayOfNMonthsAgo(0));
      case 'THIS_YEAR':
        this.setState({
          selectorValue: 'This Year'
        });
        return this.props.updateItTotalsPeriod(dateFirstDayOfNYearsAgo(0));
      case 'THIS_QUARTER':
        this.setState({
          selectorValue: 'This Quarter'
        });
        return this.props.updateItTotalsPeriod(dateFirstDayThisQuarter());
      case 'ALL_TIME':
        this.setState({
          selectorValue: 'All Time'
        });
        return this.props.updateItTotalsPeriod(dateFirstDayOfNYearsAgo(10));
      default:
        this.setState({
          selectorValue: 'This Month'
        });
        return this.props.updateItTotalsPeriod(dateFirstDayOfNMonthsAgo(0));
    }
  };

  render() {
    return (
      <div>
        <h3 className="c-gray4 h3-5 pointer" onClick={this.showMenu}>
          {this.state.selectorValue}
          <span className="dropdown-arrow ml0-5" />
        </h3>

        {this.state.showMenu ? (
          <div
            ref={element => {
              this.dropdownMenu = element;
            }}
          >
            <ul>
              <li>
                <button
                  className="mt0-5 h4 no-btn c-gray4"
                  onClick={e => this.periodSelectorHandeler(e)}
                  value={'THIS_MONTH'}
                >
                  This Month
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 h4 no-btn c-gray4"
                  onClick={e => this.periodSelectorHandeler(e)}
                  value={'THIS_QUARTER'}
                >
                  This Quarter
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 h4 no-btn c-gray4"
                  onClick={e => this.periodSelectorHandeler(e)}
                  value={'THIS_YEAR'}
                >
                  This Year
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 h4 no-btn c-gray4"
                  onClick={e => this.periodSelectorHandeler(e)}
                  value={'ALL_TIME'}
                >
                  All Time
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = accountingIt => accountingIt;

export default connect(
  mapStateToProps,
  { updateItTotalsPeriod }
)(TotalsPeriodSelector);
