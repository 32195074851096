import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import { setCurrentUser } from '../actions/auth';
import { Redirect, Switch } from 'react-router';

import { connect } from 'react-redux';

import store from '../store';

import Header from './header/Header';
import Footer from './Footer';
import Home from './Home';
import NotFound from './NotFound';
import Login from './auth/Login';
// import Register from './auth/Register';
import Log from './log/Log';
import Diary from './diary/Diary';
import Accounting from './accounting/Accounting';
import Guestbook from './guestbook/Guestbook';

import { fetchLoggedTimeToday } from '../actions/log';
import { updateModal, closeModal } from '../actions/modal';

import shortcuts from '../utils/shortcuts';
import appModal from './AppModal';

//Check Auth Token
if (localStorage.ioToken) {
  //Set auth Token header auth
  setAuthToken(localStorage.ioToken);

  //Decode Token and get User info and expiration
  const decoded = jwt_decode(localStorage.ioToken);

  //Set User and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
}

class App extends Component {
  componentDidMount() {
    this.props.fetchLoggedTimeToday();
    document.addEventListener('keyup', this.shortcutHandler);
  }

  shortcutHandler = e => {
    // Disable on input and textarea
    if (e.srcElement.localName !== 'input' && e.srcElement.localName !== 'textarea') {
      shortcuts(e, this.props, this.showModal, this.closeModal);
    }
  };

  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      <div>
        <BrowserRouter>
          {isAuthenticated ? (
            <div>
              {this.props.modal.showModal ? appModal(this.props.modal.showModal) : null}
              <Header />
              <div className="body">
                <Route exact path="/" component={Home} />
                <Route exact path="/in" component={Home} />
                <Route path="/log" component={Log} />
                <Route path="/diary" component={Diary} />
                <Route path="/accounting" component={Accounting} />
                <Route path="/guestbook" component={Guestbook} />
              </div>
              <Footer />
            </div>
          ) : (
            <div className="ml3 mt3">
              <Switch>
                <Route exact path="/" component={NotFound} />
                <Route exact path="/in" component={Login} />
                {/* <Route exact path="/register" component={Register} /> */}
                <Redirect to="/" />
              </Switch>
            </div>
          )}
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  modal: state.modal
});

export default connect(
  mapStateToProps,
  { fetchLoggedTimeToday, updateModal, closeModal }
)(App);
