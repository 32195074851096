import React, { Component } from 'react';

import RenderFields from './RenderFields';
import Field from './FieldStructure';
const {
  dateFirstDayThisQuarter,
  dateToYearDashMonthDashDay,
  dateFirstDayOfNYearsAgo
} = require('../../../utils/timeHelpers');

class Export extends Component {
  state = {
    division: 'assignments',
    period: 'last_quarter',
    start: '',
    end: ''
  };

  onSubmit = e => {
    e.preventDefault();
    const { division, period, start, end } = this.state;
    let startDate, endDate;

    //Get Start and End Dates Params
    if (period !== 'custom') {
      if (period === 'last_quarter') {
        startDate = dateToYearDashMonthDashDay(dateFirstDayThisQuarter());
        endDate = dateToYearDashMonthDashDay(new Date());
      } else if (period === 'this_year') {
        startDate = dateToYearDashMonthDashDay(dateFirstDayOfNYearsAgo(0));
        endDate = dateToYearDashMonthDashDay(new Date());
      } else if (period === 'last_year') {
        startDate = dateToYearDashMonthDashDay(dateFirstDayOfNYearsAgo(1));
        endDate = dateToYearDashMonthDashDay(dateFirstDayOfNYearsAgo(0));
      }
    } else {
      startDate = start;
      endDate = end;
    }

    //Build URL
    const baseUrl = `/api/accounting/${division}/export/`;
    const dateParams = `?start=${startDate}&end=${endDate}`;
    const fullUrl = baseUrl + dateParams;

    // // Testing - Console log
    // console.log(fullUrl);

    // Serve proper URL
    window.location.replace(fullUrl);
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);

    // //If Custom is selected show Start and End fields
    // if (this.state.period === 'custom') {
    // } else {
    // }
  };

  fieldStructure = field => {
    return (
      <Field
        key={field.name}
        type={field.type}
        label={field.label}
        name={field.name}
        placeholder={field.label}
        id={field.name}
        onChange={this.onChange}
        value={field.value}
        isSelect={field.isSelect}
        selectOptions={field.selectOptions}
        selected={this.state.currency}
        checked={this.state[field.name]}
        isRequired={field.isRequired}
      />
    );
  };

  render() {
    return (
      <div className="grid maxWidth">
        <div className="grid-1-8">
          <h1 className="mb2 h1-5">Export</h1>
          <div className="accounting-items-form-container">
            <form className="accounting-items-form" onSubmit={this.onSubmit}>
              <RenderFields fieldStructure={this.fieldStructure} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Export;
