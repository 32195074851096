import React from 'react';

import Fields from './Fields';

const RenderFields = ({ fieldStructure }) => {
  const renderFieldsCol1 = () => {
    return Fields.filter(
      field =>
        field.name === 'client' ||
        field.name === 'contact' ||
        field.name === 'description' ||
        field.name === 'is_licensing'
    ).map(field => {
      return fieldStructure(field);
    });
  };

  const renderFieldsCol2 = () => {
    return Fields.filter(
      field =>
        field.name === 'invoice_number' ||
        field.name === 'invoice_prefix' ||
        field.name === 'invoice_date' ||
        field.name === 'payment_date'
    ).map(field => {
      return fieldStructure(field);
    });
  };

  const renderFieldsCol3 = () => {
    return Fields.filter(
      field =>
        field.name === 'budget' ||
        field.name === 'currency' ||
        field.name === 'expenses' ||
        field.name === 'vat_percentage'
    ).map(field => {
      return fieldStructure(field);
    });
  };

  return (
    <React.Fragment>
      <div className="accounting-column">{renderFieldsCol1()}</div>
      <div className="accounting-column">{renderFieldsCol2()}</div>
      <div className="accounting-column">
        {renderFieldsCol3()}
        <button type="submit" className="mt1">
          Save
        </button>
      </div>
    </React.Fragment>
  );
};

export default RenderFields;
