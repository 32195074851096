import React from 'react';

interface Props {
  suggestionFor: string;
  suggestions: string[];
  onClick: any;
}

const Suggestions: React.FunctionComponent<Props> = props => {
  const { suggestionFor, suggestions, onClick } = props;

  const outputSuggestions = () => {
    return suggestions.map(suggestion => {
      return (
        <span
          key={suggestion}
          className={'suggested-item'}
          data-suggestionfor={suggestionFor}
          data-suggestion={suggestion}
          onClick={e => onClick(e)}
        >
          {suggestion}
        </span>
      );
    });
  };

  return <React.Fragment>{outputSuggestions()}</React.Fragment>;
};

export default Suggestions;
