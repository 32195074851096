import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { logoutUser } from '../actions/auth';

class Footer extends Component {
  state = {
    redirectAfterSubmit: false
  };

  onLogoutClick(e) {
    e.preventDefault();

    //call logoutUser
    this.props.logoutUser();

    //Redirect
    this.setState({ redirectAfterSubmit: true });
  }

  render() {
    if (this.state.redirectAfterSubmit) {
      return <Redirect to="/" />;
    }
    const { isAuthenticated } = this.props.auth;

    return (
      <div className="footer">
        {isAuthenticated ? (
          <React.Fragment>
            <ul>
              <li className="fw500 dib">
                <span onClick={this.onLogoutClick.bind(this)} className="c-gray5 pointer">
                  Logout
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {
    logoutUser
  }
)(Footer);
