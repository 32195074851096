import { removeByKey } from '../utils/helpers';
import { dateToYearDashMonthDashDay } from '../utils/timeHelpers';

import {
  FETCH_LOG_ITEMS,
  DELETE_LOG_ITEM,
  FETCH_LOG_TIME_LOGGED_TODAY,
  UPDATE_LOG_TIME_LOGGED_TODAY
} from '../actions/types';

const initialState = {
  byId: [],
  records: {},
  usedTimeToday: ''
};

function fetchItems(state, records) {
  return {
    ...state,
    byId: Array.from(new Set([...state.byId, ...records.map(r => r.id)])),
    records: {
      ...state.records,
      ...records.reduce((obj, record) => ({ ...obj, [record.id]: record }), {})
    }
  };
}

function deleteItem(state, id) {
  return {
    ...state,
    byId: state.byId.filter(i => i !== id),
    records: removeByKey(state.records, id)
  };
}

function fetchLoggedTimeToday(state, timeLeft) {
  return {
    ...state,
    usedTimeToday: timeLeft
  };
}

function updateLoggedTimeToday(state) {
  const today = dateToYearDashMonthDashDay(new Date());

  const usedTimeToday = state.byId
    .filter(id => dateToYearDashMonthDashDay(state.records[id].date) === today)
    .map(id => state.records[id].time)
    .reduce((a, b) => a + b, 0);

  return {
    ...state,
    usedTimeToday: usedTimeToday
  };
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOG_ITEMS:
      return fetchItems(state, action.payload);
    case DELETE_LOG_ITEM:
      return deleteItem(state, action.payload);
    case FETCH_LOG_TIME_LOGGED_TODAY:
      return fetchLoggedTimeToday(state, action.payload);
    case UPDATE_LOG_TIME_LOGGED_TODAY:
      return updateLoggedTimeToday(state, action.payload);
    default:
      return state;
  }
}
