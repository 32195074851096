import { dateFirstDayOfNMonthsAgo } from '../utils/timeHelpers';
import { FETCH_IT_ITEMS } from '../actions/types';
import { UPDATE_IT_TOTAL_PERIOD } from '../actions/types';

const initialState = {
  byId: [],
  records: {},
  incomePeriod: dateFirstDayOfNMonthsAgo(0)
};

function fetchItItems(state, records) {
  return {
    ...state,
    byId: Array.from(new Set([...state.byId, ...records.map(r => r.id)])),
    records: {
      ...state.records,
      ...records.reduce((obj, record) => ({ ...obj, [record.id]: record }), {})
    }
  };
}

const updateItTotals = (state, period) => {
  console.log('period: ', period);
  return {
    ...state,
    incomePeriod: period
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_IT_ITEMS:
      return fetchItItems(state, action.payload);
    case UPDATE_IT_TOTAL_PERIOD:
      return updateItTotals(state, action.payload);
    default:
      return state;
  }
}
