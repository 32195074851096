import React from 'react';

import { normalizeArr } from '../../utils/dataHelpers';

interface Props {
  data: number[];
}

const displayBarsGraph: React.FunctionComponent<Props> = props => {
  const { data } = props;

  const generateGraph = () => {
    const normalizedData = normalizeArr(data);

    return normalizedData.map((i: number, index: number) => {
      const barHeight = {
        height: i + '%'
      };

      return (
        <div key={index} className="vertical-bar-wrapper">
          <div className="vertical-bar-block">
            <div className="vertical-bar" style={barHeight} />
          </div>
        </div>
      );
    });
  };

  return <div className="vertical-chart">{generateGraph()}</div>;
};

export default displayBarsGraph;
