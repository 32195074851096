import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AccountingMenu extends Component {
  render() {
    return (
      <div className="accounting-menu maxWidth mb3">
        <ul className="left">
          <li>
            <Link to={'/accounting/'}>
              <span className="c-black">Assignments</span>
            </Link>
          </li>
          <li>
            <Link to={'/accounting/it'}>
              <span className="c-black">illo.tools</span>
            </Link>
          </li>
          <li className="dib">
            <Link to={'/accounting/export'}>
              <span className="c-black">Export</span>
            </Link>
          </li>
        </ul>

        <div className="right" />
        <ul />
      </div>
    );
  }
}

export default AccountingMenu;
