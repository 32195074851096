import { removeByKey } from '../utils/helpers';
import { FETCH_DIARY_ITEMS, DELETE_DIARY_ITEM } from '../actions/types';

const initialState = {
  byId: [],
  records: {}
};

function fetchItems(state, records) {
  return {
    ...state,
    byId: Array.from(new Set([...state.byId, ...records.map(r => r.id)])),
    records: {
      ...state.records,
      ...records.reduce((obj, record) => ({ ...obj, [record.id]: record }), {})
    }
  };
}

function deleteItem(state, id) {
  return {
    ...state,
    byId: state.byId.filter(i => i !== id),
    records: removeByKey(state.records, id)
  };
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DIARY_ITEMS:
      return fetchItems(state, action.payload);
    case DELETE_DIARY_ITEM:
      return deleteItem(state, action.payload);
    default:
      return state;
  }
}
