import React from 'react';

const Field = ({
  input,
  name,
  value,
  label,
  id,
  type,
  isSelect,
  selectOptions,
  selected,
  checked,
  isRequired,
  nextInvoiceNumber,
  onChange
}) => {
  const customPlaceholder = name === 'invoice_number' ? `Next #${nextInvoiceNumber}` : label;
  const outputField = () => {
    // If checkbox
    if (type === 'checkbox') {
      return (
        <div className={`${name} switcher-container`}>
          <label htmlFor={id} className={`minimal-form ${name}`}>
            {label}
          </label>
          <span className={`minimal-form ${name}`} />
          <div className="mt1">
            <label className={`switcher`}>
              <input
                {...input}
                name={name}
                placeholder={label}
                id={id}
                type={type}
                value={value}
                checked={checked}
                onChange={onChange}
                className="accounting-field minimal-form"
                required={isRequired}
              />
              <span className="switcher-slider round" />
            </label>
          </div>
        </div>
      );

      // Other fields
    } else {
      return (
        <div className={name}>
          <label htmlFor={id} className={`minimal-form ${name}`}>
            {label}
          </label>
          <span className={`dib error minimal-form ml0-5 hidden ${name}`}>
            {isRequired ? 'Required' : ''}
          </span>

          {isSelect === true ? ( // If is select
            <React.Fragment>
              <select
                name={name}
                onChange={onChange}
                id={id}
                value={selected}
                className="minimal-form"
              >
                {selectOptions.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </React.Fragment>
          ) : (
            // Any other fields
            <React.Fragment>
              <input
                {...input}
                name={name}
                placeholder={customPlaceholder}
                id={id}
                step={name === 'budget' || name === 'expenses' ? 'any' : null} // Make money fields able to handle decimals
                type={type}
                value={value}
                checked={checked}
                onChange={onChange}
                className="accounting-field minimal-form"
                required={isRequired}
              />
            </React.Fragment>
          )}
        </div>
      );
    }
  };

  return <React.Fragment>{outputField()}</React.Fragment>;
};

export default Field;
