import React from 'react';
import PropTypes from 'prop-types';

import LogAddItem from './log/AddItem';
import DiaryAddItem from './diary/AddItem';

const appModal = component => {
  const outputComponent = () => {
    if (component === 'log') {
      return <LogAddItem />;
    } else if (component === 'diary') {
      return <DiaryAddItem />;
    }
  };

  return (
    <div className="modal">
      <div className="modal-container">{outputComponent()}</div>
    </div>
  );
};

appModal.propTypes = {
  component: PropTypes.string.isRequired
};

export default appModal;
