import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import axios from 'axios';
import PropTypes from 'prop-types';

import { editDiaryItem, fetchDiaryItems } from '../../actions/diary';
import { closeModal } from '../../actions/modal';

import Rating from '../inputs/RatingInteractive';
import Input from '../inputs/Input';
import Textarea from '../inputs/Textarea';

import { isEmpty } from '../../utils/dataHelpers';
import { getLastNumberInUrl } from '../../utils/helpers';
import { dateToYearDashMonthDashDay } from '../../utils/timeHelpers';
import validate from '../inputs/InputsValidation';

class EditItem extends Component {
  state = {
    date: '',
    location: '',
    notes: '',
    mood_personal: 0,
    mood_work: 0,
    energy: 0,
    stress: 0,
    image: '',
    file: null,
    redirectAfterSubmit: false,
    errors: {}
  };

  componentDidMount() {
    this.handleInitialize();
  }

  async handleInitialize() {
    //If id is not in props.location get it from URL
    const id = this.props.location.id || getLastNumberInUrl();

    //If id is not in props.location, means there is no state. Fetch it
    if (!this.props.location.id) {
      await this.props.fetchDiaryItems();
    }

    //Match id and get Item Data from the Store via props.
    const itemData = this.props.diary.records[id];

    //Add item data to State
    this.setState({
      ...itemData,
      date: dateToYearDashMonthDashDay(itemData.date),
      notes: itemData.notes === null ? undefined : itemData.notes,
      id
    });
  }

  onRatingClick = e => {
    const newRatingValue = Number(e.currentTarget.value);
    this.setState({ [e.currentTarget.name]: newRatingValue });
  };

  onChange = e => {
    // Add Required Validation span when field is empty
    if (e.target.required && !isEmpty(e.target.value)) {
      document.querySelector(`.${e.target.name}.error`).classList.add('hidden');
    } else {
      document.querySelector(`.${e.target.name}.error`).classList.remove('hidden');
    }

    // Update the state
    this.setState({ [e.target.name]: e.target.value });
  };

  onFileSelection = e => {
    this.setState({ file: e.target.files[0] });
  };
  submitWithoutImage = async e => {
    const { date, location, notes, mood_personal, mood_work, energy, stress, file } = this.state;

    // Build Object to DB
    const objToDB = {
      id: this.state.id,
      date,
      location,
      notes,
      mood_personal,
      mood_work,
      energy,
      stress,
      image: file
    };

    // Save to DB, update time Left and Redirect.
    try {
      await this.props.editDiaryItem(objToDB);
    } catch (err) {
      console.log(err);
    }
  };

  submitWithImage = async e => {
    // Build the formData to submit
    const formData = new FormData();

    // to DB
    formData.append('date', this.state.date);
    formData.append('location', this.state.location);
    formData.append('notes', this.state.notes);
    formData.append('mood_personal', this.state.mood_personal);
    formData.append('mood_work', this.state.mood_work);
    formData.append('energy', this.state.energy);
    formData.append('stress', this.state.stress);

    // Actual image
    formData.append('image', this.state.file); // It should be the last bit

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    // Save to DB
    try {
      await axios.put('/api/diary', formData, config);
    } catch (err) {
      console.log(err);
    }
  };

  onSubmit = e => {
    e.preventDefault();

    //Validate Form

    // Display validation
    const validateArr = ['location'];
    if (!isEmpty(validate(validateArr, this.state))) {
      return;
    }

    if (this.state.file === null) {
      this.submitWithoutImage(e);
    } else {
      this.submitWithImage(e);
    }

    // Redirect
    this.redirectHandler();
  };

  redirectHandler = () => {
    // If no modal, redirect.
    if (this.props.modal.showModal === '') {
      this.setState({ redirectAfterSubmit: true });
      // If modal, don't redirect, but close the modal.
    } else {
      this.props.closeModal();
    }
  };

  render() {
    if (this.state.redirectAfterSubmit) {
      return <Redirect to="/diary" />;
    }
    return (
      <div className="maxWidth">
        <h1 className="h1-5 mb2">Edit Diary from {this.state.date}</h1>

        <div>
          <h3>
            <span>Mood Personal: </span>
            <Rating
              name={'mood_personal'}
              ratingValue={this.state.mood_personal}
              lowestValue={-2}
              numberOfPoints={5}
              onClick={this.onRatingClick}
            />
          </h3>
          <h3>
            <span>Mood Work: </span>
            <Rating
              name={'mood_work'}
              ratingValue={this.state.mood_work}
              lowestValue={-2}
              numberOfPoints={5}
              onClick={this.onRatingClick}
            />
          </h3>
          <h3>
            <span>Energy: </span>
            <Rating
              name={'energy'}
              ratingValue={this.state.energy}
              lowestValue={-2}
              numberOfPoints={5}
              onClick={this.onRatingClick}
            />
          </h3>
          <h3 className="mb2">
            <span>Stress: </span>
            <Rating
              name={'stress'}
              ratingValue={this.state.stress}
              lowestValue={-2}
              numberOfPoints={5}
              onClick={this.onRatingClick}
            />
          </h3>
        </div>

        <form onSubmit={this.onSubmit}>
          <div className="minimal-form-column">
            {/* <Input label={'Image'} name={'image'} type={'file'} onChange={this.onFileSelection} /> */}

            <Input
              label={'Date'}
              name={'date'}
              type={'date'}
              isRequired={true}
              value={this.state.date}
              onChange={this.onChange}
            />
            <Input
              label={'Location'}
              name={'location'}
              type={'text'}
              isRequired={true}
              value={this.state.location}
              onChange={this.onChange}
              // customClass={'mb0'}
            />

            <Textarea
              label={'Notes'}
              name={'notes'}
              value={this.state.notes}
              onChange={this.onChange}
              // customClass={'mb0'}
            />

            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    );
  }
}

EditItem.propTypes = {
  diary: PropTypes.exact({
    records: PropTypes.object.isRequired,
    byId: PropTypes.array.isRequired
  }),
  fetchDiaryItems: PropTypes.func.isRequired,
  editDiaryItem: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

function mapStateToProps({ diary, modal }) {
  return { diary, modal };
}

export default connect(
  mapStateToProps,
  { fetchDiaryItems, editDiaryItem, closeModal }
)(EditItem);
