import axios from 'axios';
import { FETCH_DIARY_ITEMS, ADD_DIARY_ITEM, EDIT_DIARY_ITEM, DELETE_DIARY_ITEM } from './types';

// ----------------------------------------------------------------------
// ---------------------- BASIC DIARY ACTIONS -----------------------------
// ----------------------------------------------------------------------

export const fetchDiaryItems = () => async dispatch => {
  try {
    const res = await axios.get('/api/diary/');
    dispatch({ type: FETCH_DIARY_ITEMS, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const addDiaryItem = values => async dispatch => {
  try {
    const res = await axios.post('/api/diary/', values);
    dispatch({ type: ADD_DIARY_ITEM, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const editDiaryItem = values => async dispatch => {
  try {
    const res = await axios.put('/api/diary/', values);
    dispatch({ type: EDIT_DIARY_ITEM, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteDiaryItem = id => async dispatch => {
  try {
    await axios.delete(`/api/diary/${id}`);
    dispatch({ type: DELETE_DIARY_ITEM, payload: id });
  } catch (error) {
    console.log(error);
  }
};

// ----------------------------------------------------------------------
// ---------------------- EXTRA DIARY ACTIONS -----------------------------
// ----------------------------------------------------------------------
