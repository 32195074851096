import React from 'react';

import AllItems from './Alltems';

const DiaryHome = () => {
  return (
    <div className="grid maxWidth">
      <div className="grid-1-8">
        <AllItems />
      </div>
    </div>
  );
};

export default DiaryHome;
