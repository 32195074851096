import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { sortAssignments, sortOrderAssignments } from '../../../actions/accounting';

class SortAssignments extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      selectorValue: 'Status'
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  sortByHandler = e => {
    switch (e.target.value) {
      case 'STATUS':
        this.setState({
          selectorValue: 'Status'
        });
        return this.props.sortAssignments('status');
      case 'INVOICE_NUMBER':
        this.setState({
          selectorValue: 'Invoice Number'
        });
        return this.props.sortAssignments('invoice_number');
      case 'INVOICE_DATE':
        this.setState({
          selectorValue: 'Invoice Date'
        });
        return this.props.sortAssignments('invoice_date');
      case 'PAYMENT_DATE':
        this.setState({
          selectorValue: 'Payment Date'
        });
        return this.props.sortAssignments('payment_date');
      case 'REVENUE':
        this.setState({
          selectorValue: 'Revenue'
        });
        return this.props.sortAssignments('revenue');
      case 'CLIENT':
        this.setState({
          selectorValue: 'Client'
        });
        return this.props.sortAssignments('client');
      default:
        this.setState({
          selectorValue: 'Status'
        });
        return this.props.sortAssignments('status');
    }
  };

  sortOrderHandler = e => {
    switch (e.target.value) {
      case 'ASC':
        return this.props.sortOrderAssignments('ASC');
      case 'DESC':
        return this.props.sortOrderAssignments('DESC');
      default:
        return this.props.sortOrderAssignments('ASC');
    }
  };

  render() {
    return (
      <div className="sort dib ml1">
        <button
          className={
            'icon-btn line mr0-25 sort-order ' +
            (this.props.accountingAssignments.sortOrder === 'ASC' ? 'desc' : 'asc')
          }
          onClick={e => this.sortOrderHandler(e)}
        >
          <svg viewBox="0 0 24 24">
            <line x1="12" y1="20" x2="12" y2="10" />
            <line x1="18" y1="20" x2="18" y2="4" />
            <line x1="6" y1="20" x2="6" y2="16" />
          </svg>
        </button>
        <span className="c-gray4 fw700 pointer" onClick={this.showMenu}>
          {this.state.selectorValue}
          <span className="dropdown-arrow-small ml0-5" />
        </span>

        {this.state.showMenu ? (
          <div
            className="sort-options"
            ref={element => {
              this.dropdownMenu = element;
            }}
          >
            <ul>
              <li>
                <button
                  className="mt0-5 no-btn c-gray4 fw700"
                  onClick={e => this.sortByHandler(e)}
                  value={'STATUS'}
                >
                  Status
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 no-btn c-gray4 fw700"
                  onClick={e => this.sortByHandler(e)}
                  value={'INVOICE_NUMBER'}
                >
                  Invoice Number
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 no-btn c-gray4 fw700"
                  onClick={e => this.sortByHandler(e)}
                  value={'INVOICE_DATE'}
                >
                  Invoice Date
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 no-btn c-gray4 fw700"
                  onClick={e => this.sortByHandler(e)}
                  value={'PAYMENT_DATE'}
                >
                  Payment Date
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 no-btn c-gray4 fw700"
                  onClick={e => this.sortByHandler(e)}
                  value={'REVENUE'}
                >
                  Revenue
                </button>
              </li>
              <li>
                <button
                  className="mt0-25 no-btn c-gray4 fw700"
                  onClick={e => this.sortByHandler(e)}
                  value={'CLIENT'}
                >
                  Client
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

SortAssignments.propTypes = {
  sortAssignments: PropTypes.func.isRequired,
  sortOrderAssignments: PropTypes.func.isRequired
};

const mapStateToProps = accountingAssignments => accountingAssignments;

export default connect(
  mapStateToProps,
  { sortAssignments, sortOrderAssignments }
)(SortAssignments);
