import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from 'react-spring/renderprops';

class HeaderNav extends Component {
  state = {
    hoverOn: false
  };

  hoverHandler = () => {
    this.setState({ hoverOn: !this.state.hoverOn });
  };

  outputNavOnHover = () => {
    return (
      <ul>
        <li className="fw500">
          <Link to={'/log'}>Logs</Link>
        </li>
        <li className="fw500">
          <Link to={'/diary'}>Diary</Link>
        </li>
        <li className="fw500">
          <Link to={'/accounting'}>Accounting</Link>
        </li>
        <li className="fw500">
          <Link to={'/guestbook'}>Guestbook</Link>
        </li>
      </ul>
    );
  };

  render() {
    const hover = this.state.hoverOn;
    return (
      <div className="left" onMouseEnter={this.hoverHandler} onMouseLeave={this.hoverHandler}>
        <Link to={'/'} id="logo">
          o–o
        </Link>
        <Transition
          config={{ duration: 100 }}
          items={hover}
          from={{ opacity: 0, transform: 'translate3d(-1rem, 0, 0)' }}
          enter={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
          leave={{ opacity: 0, transform: 'translate3d(-1rem, 0, 0)' }}
        >
          {hover => (hover ? props => <div style={props}>{this.outputNavOnHover()}</div> : null)}
        </Transition>
      </div>
    );
  }
}

export default HeaderNav;
