import React from 'react';

interface Props {
  name: string;
  value?: string;
  label: string;
  id?: string;
  type: string;
  checked?: boolean;
  isRequired?: boolean;
  onChange: any;
  placeholder?: string;
  customClass?: string;
}

const Input: React.FunctionComponent<Props> = props => {
  const {
    name,
    value,
    label,
    id,
    type,
    checked,
    isRequired,
    onChange,
    placeholder,
    customClass
  } = props;

  const inputStructure = () => {
    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
      // Prevent default for enter key
      if (e.keyCode === 13) {
        e.preventDefault();
      }

      // Unfocus the field
      if (e.key === 'Escape') {
        (e.target as HTMLInputElement).blur();
      }
    };

    return (
      <div className={`minimal-form-item ${name}${customClass ? ' ' + customClass : ''}`}>
        <label htmlFor={id || name} className={`minimal-form ${name}`}>
          {label}
        </label>
        <span className={`dib error minimal-form ml0-5 hidden ${name}`}>
          {isRequired ? 'Required' : ''}
        </span>
        <React.Fragment>
          <input
            name={name}
            id={id || name}
            type={type}
            value={value}
            checked={checked}
            onChange={onChange}
            onKeyUp={onKeyUp}
            className="minimal-form minimal-form-field"
            required={isRequired}
            placeholder={placeholder || label}
          />
        </React.Fragment>
      </div>
    );
  };

  return <React.Fragment>{inputStructure()}</React.Fragment>;
};

export default Input;
