import React, { Component } from 'react';
import { Route } from 'react-router-dom';

// import LogMenu from './LogMenu';
import Home from './Home';
import AddItem from './AddItem';
import EditItem from './EditItem';
import Stats from './Stats';

import './log.css';
import '../dataVisualization/dataVisualization.css';

class Log extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <LogMenu /> */}
        <Route exact path="/log" component={Home} />
        <Route exact path="/log/stats" component={Stats} />
        <Route exact path="/log/add" component={AddItem} />
        <Route exact path="/log/edit/:id" component={EditItem} />
      </React.Fragment>
    );
  }
}

export default Log;
