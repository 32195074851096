import React from 'react';

import AllItems from './AllItems';

const LogHome = () => {
  return (
    <div className="grid maxWidth">
      <div className="grid-1-8">
        <AllItems />
      </div>
    </div>
  );
};

export default LogHome;
