import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchItItems } from '../../../actions/accounting';
import Item from './Item';

class AllItems extends Component {
  componentDidMount() {
    this.props.fetchItItems();
  }

  render() {
    return (
      <React.Fragment>
        <h1 className="c-black mb2 h1-5">Projects</h1>
        <ul className="accounting-items">
          {this.props.accountingIt.byId.map(id => {
            return <Item key={id} id={id} />;
          })}
        </ul>
      </React.Fragment>
    );
  }
}

const mapStateToProps = accountingIt => accountingIt;

export default connect(
  mapStateToProps,
  { fetchItItems }
)(AllItems);
