export const removeByKey = (obj, deleteKey) => {
  return Object.keys(obj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = obj[current];
      return result;
    }, {});
};

export const getLastNumberInUrl = () => {
  const url = window.location.pathname;
  const allNumbers = url
    .replace(/[^0-9]/g, ' ')
    .trim()
    .split(/\s+/);
  return parseInt(allNumbers[allNumbers.length - 1], 10);
};
