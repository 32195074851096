import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import './guestbook.css';

import Home from './Home';

class Log extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/guestbook" component={Home} />
      </React.Fragment>
    );
  }
}

export default Log;
