import React, { Component } from 'react';
import { Route } from 'react-router-dom';

// import DiaryMenu from './DiaryMenu';
import Home from './Home';
import AddItem from './AddItem';
import EditItem from './EditItem';

import './diary.css';

class Diary extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <DiaryMenu /> */}
        <Route exact path="/diary" component={Home} />
        <Route exact path="/diary/add" component={AddItem} />
        <Route exact path="/diary/edit/:id" component={EditItem} />
      </React.Fragment>
    );
  }
}

export default Diary;
