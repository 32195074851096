import React, { Component } from 'react';
import { connect } from 'react-redux';

class Item extends Component {
  render() {
    const id = this.props.id;
    const item = this.props.accountingIt.records[id];
    return (
      <li key={id} className="accounting-item db mb0-75">
        <div>
          <h3 className="dib">Invoice #{item.invoice_number}</h3>
          <h3 className="dib ml1">{item.invoice_date}</h3>
          <h3 className="dib ml1 c-main">{item.total}€</h3>
        </div>
      </li>
    );
  }
}

function mapStateToProps({ accountingIt }) {
  return { accountingIt };
}

export default connect(
  mapStateToProps,
  {}
)(Item);
