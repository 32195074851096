export default [
  {
    label: 'Division',
    name: 'division',
    type: 'isSelect',
    isSelect: true,
    selectOptions: [
      { name: 'Assignments', value: 'assignments' },
      { name: 'illustration.tools', value: 'it' }
    ]
  },
  {
    label: 'Period',
    name: 'period',
    type: 'isSelect',
    isSelect: true,
    selectOptions: [
      { name: 'Last Quarter', value: 'last_quarter' },
      { name: 'Last Year', value: 'last_year' },
      { name: 'Custom', value: 'custom' }
    ]
  },
  {
    label: 'Start',
    name: 'start',
    type: 'date'
  },
  {
    label: 'End',
    name: 'end',
    type: 'date'
  }
];
