import React from 'react';
import { connect } from 'react-redux';

import { IGuestbook } from './IGuestbook';
import { deleteGuestbookItem } from '../../actions/guestbook';

interface IProps {
  id: number;
  guestbook: IGuestbook;
  deleteGuestbookItem: any;
}

const ItemActions: React.FunctionComponent<IProps> = props => {
  const id = props.id;
  const { fav, submitted, submission_approved, submitter } = props.guestbook.records[id];

  const handleDelete = async (id: number) => {
    await props.deleteGuestbookItem(id);
  };

  return (
    <div className="actions">
      {/* {fav ? <span>Fav YES</span> : <span>Fav NO</span>} */}
      <button onClick={() => handleDelete(id)}>DELETE</button>
    </div>
  );
};

const mapStateToProps = ({ guestbook }: IProps) => {
  return { guestbook };
};

const mapDispatchToProps = {
  deleteGuestbookItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemActions);
