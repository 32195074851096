import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchLogItems } from '../../actions/log';
import Item from './Item';

import { ILog } from './ILog';

interface Props {
  fetchLogItems(): Promise<void>;
  log: ILog;
}

const AllItems: React.FunctionComponent<Props> = props => {
  useEffect(() => {
    props.fetchLogItems().catch(err => console.error(err));
  }, []);

  return (
    <React.Fragment>
      <h1 className="c-black mb2 h1-5">Logs</h1>
      <Link to={'/log/add'} className="ml1">
        <span className="add-item-button c-main fw700">+ Add</span>
      </Link>
      <ul className="log-items">
        {props.log.byId.map(id => {
          return <Item key={id} id={id} />;
        })}
      </ul>
    </React.Fragment>
  );
};

function mapStateToProps({ log }: Props) {
  return { log };
}

const mapDispatchToProps = { fetchLogItems };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllItems);
