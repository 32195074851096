import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchDiaryItems } from '../../actions/diary';
import Item from './Item';

class AllItems extends Component {
  componentDidMount() {
    this.props.fetchDiaryItems();
  }

  render() {
    return (
      <React.Fragment>
        <h1 className="c-black mb2 h1-5">Diary</h1>
        <Link to={'/diary/add'} className="ml1">
          <span className="add-item-button c-main fw700">+ Add</span>
        </Link>
        <ul className="diary-items">
          {this.props.diary.byId.map(id => {
            return <Item key={id} id={id} />;
          })}
        </ul>
      </React.Fragment>
    );
  }
}

AllItems.propTypes = {
  diary: PropTypes.exact({
    records: PropTypes.object.isRequired,
    byId: PropTypes.array.isRequired
  }),
  fetchDiaryItems: PropTypes.func.isRequired
};

const mapStateToProps = diary => diary;

export default connect(
  mapStateToProps,
  { fetchDiaryItems }
)(AllItems);
