import axios from 'axios';
import {
  FETCH_ACCOUNTING_ITEMS,
  ADD_ACCOUNTING_ITEM,
  EDIT_ACCOUNTING_ITEM,
  DELETE_ACCOUNTING_ITEM,
  UPDATE_ASSIGNMENTS_TOTAL_PERIOD,
  SORT_ASSIGNMENTS,
  SORT_ORDER_ASSIGNMENTS,
  FETCH_NEXT_INVOICE_NUMBER,
  FETCH_IT_ITEMS,
  UPDATE_IT_TOTAL_PERIOD
} from './types';

// ----------------------------------------------------------------------
// ------------------- BASIC ACCOUNTING ACTIONS -------------------------
// ----------------------------------------------------------------------

// Assignments
export const fetchAssignmentsItems = () => async dispatch => {
  try {
    const res = await axios.get('/api/accounting/assignments/');
    dispatch({ type: FETCH_ACCOUNTING_ITEMS, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const addAssignmentItem = values => async dispatch => {
  try {
    const res = await axios.post('/api/accounting/assignments/', values);
    dispatch({ type: ADD_ACCOUNTING_ITEM, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const editAssignmentItem = values => async dispatch => {
  try {
    const res = await axios.put('/api/accounting/assignments/', values);
    dispatch({ type: EDIT_ACCOUNTING_ITEM, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteAssignmentItem = id => async dispatch => {
  try {
    await axios.delete(`/api/accounting/assignments/${id}`);
    dispatch({ type: DELETE_ACCOUNTING_ITEM, payload: id });
  } catch (error) {
    console.log(error);
  }
};

// ----------------------------------------------------------------------
// ------------------- EXTRA ACCOUNTING ACTIONS -------------------------
// ----------------------------------------------------------------------

export const updateAssignmentsTotalsPeriod = period => dispatch => {
  dispatch({ type: UPDATE_ASSIGNMENTS_TOTAL_PERIOD, payload: period });
};

// Sort Assignments
export const sortAssignments = sortBy => dispatch => {
  dispatch({ type: SORT_ASSIGNMENTS, payload: sortBy });
};

// Sorting Order
export const sortOrderAssignments = order => dispatch => {
  dispatch({ type: SORT_ORDER_ASSIGNMENTS, payload: order });
};

// IT Invoices
export const fetchItItems = () => async dispatch => {
  try {
    const res = await axios.get('/api/accounting/it/');
    dispatch({ type: FETCH_IT_ITEMS, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

export const updateItTotalsPeriod = period => dispatch => {
  dispatch({ type: UPDATE_IT_TOTAL_PERIOD, payload: period });
};

// ---------- Unused ----------
export const fetchNextInvoiceNumber = () => async dispatch => {
  try {
    const res = await axios.get('/api/accounting/assignments/');
    dispatch({ type: FETCH_NEXT_INVOICE_NUMBER, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};
