import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { IStatsLogAreas } from './ILog';

import BarsGraph from '../dataVisualization/BarsGraph';
import HorizontalBar from '../dataVisualization/HorizontalBar';

interface LoggedTimeItem {
  date: string;
  time: number;
}

const Stats: React.FunctionComponent = () => {
  const [areas, setAreas] = useState<IStatsLogAreas[]>([]);
  const [loggedTime, setLoggedTime] = useState<number[]>([]);

  useEffect(() => {
    handleInitialize().catch(err => console.error(err));
  }, []);

  const handleInitialize = async () => {
    try {
      Promise.all([fetchLoggedTime(), fetchAreas()]);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const fetchLoggedTime = async () => {
    const res = await axios.get('/api/log/logged-time?days=30');
    const loggedTime = res.data.map((x: LoggedTimeItem) => x.time);
    setLoggedTime(loggedTime);
  };

  const fetchAreas = async () => {
    const res = await axios.get('/api/log/logged-time-by-area?days=30');
    setAreas(res.data);
  };

  return (
    <div className="maxWidth">
      <h1 className="h1-5 mb2">Stats</h1>
      <h3>Daily Time</h3>
      <div className="mb6 p3">
        <BarsGraph data={loggedTime} />
      </div>
      <h3>Areas</h3>
      <div className="mb6 p3">
        <HorizontalBar data={areas} />
      </div>
    </div>
  );
};

export default Stats;
