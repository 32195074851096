export default [
  { label: 'Client', name: 'client', type: 'text', isRequired: true },
  { label: 'Description', name: 'description', type: 'text' },
  { label: 'Contact', name: 'contact', type: 'text' },
  {
    label: 'Is Licensing?',
    name: 'is_licensing',
    type: 'checkbox'
  },
  {
    label: 'Budget',
    name: 'budget',
    type: 'number',
    isRequired: true
  },
  {
    label: 'Currency',
    name: 'currency',
    type: 'isSelect',
    isSelect: true,
    selectOptions: [
      { name: '€', value: 'EUR' },
      { name: '$', value: 'USD' },
      { name: '£', value: 'GBP' },
      { name: 'SEK', value: 'SEK' },
      { name: 'C$', value: 'CAD' },
      { name: 'A$', value: 'AUD' }
    ]
  },
  {
    label: 'Expenses',
    name: 'expenses',
    type: 'number'
  },
  {
    label: 'VAT %',
    name: 'vat_percentage',
    type: 'number'
  },
  { label: 'Invoice #', name: 'invoice_number', type: 'number' },
  { label: 'Invoice Prefix', name: 'invoice_prefix', type: 'text' },
  {
    label: 'Invoice Date',
    name: 'invoice_date',
    type: 'date'
  },
  {
    label: 'Payment Date',
    name: 'payment_date',
    type: 'date'
  }
];
