import React from 'react';

import AssignmentsAllItems from './assignments/Alltems';
import AssignmentsTotals from './assignments/Totals';

const AccountingHome = () => {
  return (
    <div className="grid maxWidth">
      <div className="grid-1-8">
        <AssignmentsAllItems />
      </div>
      <div className="grid-8-10">
        <AssignmentsTotals />
      </div>
    </div>
  );
};

export default AccountingHome;
