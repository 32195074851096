import { combineReducers } from 'redux';

import auth from './auth';
import modal from './modal';
import accountingAssignments from './accountingAssignments';
import accountingIt from './accountingIt';
import log from './log';
import diary from './diary';
import guestbook from './guestbook';
import errors from './errors';

export default combineReducers({
  auth,
  modal,
  accountingAssignments,
  accountingIt,
  log,
  diary,
  guestbook,
  errors
});
