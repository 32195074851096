import React from 'react';

const displayDots = (num: number) => {
  const dots = () => {
    return Array(num)
      .fill('')
      .map((_, i) => {
        return <span key={i}>&#9679;</span>;
      });
  };

  return <React.Fragment>{dots()}</React.Fragment>;
};

export default displayDots;
