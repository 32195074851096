import React from 'react';
import PropTypes from 'prop-types';

import { currencySymbol } from '../../../utils/currencyHelpers';
import { toMonthDayCommaYear } from '../../../utils/timeHelpers';

const ItemMoreInfo = ({ item }) => {
  const {
    description,
    contact,
    is_licensing,
    currency,
    budget,
    expenses,
    revenue,
    invoice_number,
    invoice_number_with_prefix,
    invoice_date,
    payment_date
  } = item;

  const money = () => {
    if (expenses) {
      return (
        <React.Fragment>
          <div>
            Budget:{' '}
            <span>
              {currencySymbol(currency)}
              {budget}
            </span>
          </div>
          <div>
            Collaborators: {currencySymbol(currency)}
            {expenses}
          </div>
          <div>
            Revenue:{' '}
            <span className="c-main">
              {currencySymbol(currency)}
              {revenue}
            </span>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div>
          Budget:{' '}
          <span className="c-main">
            {currencySymbol(currency)}
            {budget}
          </span>
        </div>
      );
    }
  };

  return (
    <div className="more-info">
      <div>
        {description ? <div>{description}</div> : null}
        {contact ? <div>Contact: {contact}</div> : null}
        {is_licensing ? <div>✔ Licensing</div> : null}
      </div>

      <div>{money()}</div>

      <div>
        {invoice_number ? <div>Invoice Number: {invoice_number_with_prefix}</div> : null}
        {invoice_date ? <div>Invoice date: {toMonthDayCommaYear(invoice_date)}</div> : null}
        {payment_date ? <div>Payment date: {toMonthDayCommaYear(payment_date)}</div> : null}
      </div>
    </div>
  );
};

ItemMoreInfo.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemMoreInfo;
