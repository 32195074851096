import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ItemActions from './ItemActions';
import RatingDisplay from '../inputs/RatingDisplay';
import { deleteDiaryItem } from '../../actions/diary';

const { dateToYearDashMonthDashDay } = require('../../utils/timeHelpers');

class Item extends Component {
  render() {
    const id = this.props.id;
    const item = this.props.diary.records[id];
    return (
      <li key={id} className="diary-item db mb0-75">
        <div className="dib">
          <h4 className="dib">{dateToYearDashMonthDashDay(item.date)}</h4>
          <h4 className="dib ml0-5 c-main">{item.location}</h4>
          <h4 className="dib ml0-5 c-gray4 h5">
            Mood Personal:{' '}
            <RatingDisplay ratingValue={item.mood_personal} numberOfPoints={5} lowestValue={-2} />
          </h4>
          <h4 className="dib ml0-5 c-gray4 h5">
            Mood Work:{' '}
            <RatingDisplay ratingValue={item.mood_work} numberOfPoints={5} lowestValue={-2} />
          </h4>
          <h4 className="dib ml0-5 c-gray4 h5">
            Energy: <RatingDisplay ratingValue={item.energy} numberOfPoints={5} lowestValue={-2} />
          </h4>
          <h4 className="dib ml0-5 c-gray4 h5">
            Stress: <RatingDisplay ratingValue={item.stress} numberOfPoints={5} lowestValue={-2} />
          </h4>
        </div>
        <ItemActions id={id} props={this.props} />
      </li>
    );
  }
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  diary: PropTypes.exact({
    records: PropTypes.object.isRequired,
    byId: PropTypes.array.isRequired
  }),
  deleteDiaryItem: PropTypes.func.isRequired
};

function mapStateToProps({ diary }) {
  return { diary };
}

export default connect(
  mapStateToProps,
  { deleteDiaryItem }
)(Item);
