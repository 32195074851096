import { createStore, compose } from 'redux';

import reducers from './reducers';
import middleware from './middleware';

const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, initialState, composeEnhancers(middleware));

export default store;
