import React from 'react';
import { connect } from 'react-redux';

import { ILog } from './ILog';

import ItemActions from './ItemActions';
import RatingDisplay from '../inputs/RatingDisplay';

const { dateToYearDashMonthDashDay } = require('../../utils/timeHelpers');

interface Props {
  id: number;
  log: ILog;
}

const Item: React.FunctionComponent<Props> = props => {
  const id = props.id;
  const { date, area, project, time, mood_before, mood_after } = props.log.records[id];
  return (
    <li key={id} className="log-item db mb0-75">
      <div className="dib">
        <h4 className="dib">{dateToYearDashMonthDashDay(date)}</h4>
        <h4 className="dib ml0-5 c-main">{area}</h4>
        <h4 className="dib ml0-5 c-main">{project}</h4>
        <h4 className="dib ml0-5 c-gray4 h5">
          Time: <RatingDisplay ratingValue={time} numberOfPoints={10} lowestValue={1} />
        </h4>
        <h4 className="dib ml0-5 c-gray4 h5">
          Mood Before:{' '}
          <RatingDisplay ratingValue={mood_before} numberOfPoints={5} lowestValue={-2} />
        </h4>
        <h4 className="dib ml0-5 c-gray4 h5">
          Mood After: <RatingDisplay ratingValue={mood_after} numberOfPoints={5} lowestValue={-2} />
        </h4>
      </div>
      <ItemActions id={id} />
    </li>
  );
};

const mapStateToProps = ({ log }: Props) => {
  return { log };
};

export default connect(
  mapStateToProps,
  {}
)(Item);
